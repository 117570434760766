import React, { FC, useEffect, useState, useRef, RefObject } from 'react';
import { EditorContent, useEditor, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Underline } from '@tiptap/extension-underline';
import CharacterCount from '@tiptap/extension-character-count';
import styled from 'styled-components';
import Select, { StylesConfig, CSSObjectWithLabel } from 'react-select';
import { Button } from 'antd';
import {
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    UnorderedListOutlined,
    OrderedListOutlined,
    ColumnWidthOutlined,
    RedoOutlined,
    UndoOutlined
} from '@ant-design/icons';

const limit = 3000;

const ToolbarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flext-start;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: -1px;
`;

const TextEditorWrapper = styled.div`
    position: relative;
    z-index: 2;
`;

const CharacterLimit = styled.div`
    color: #868e96;
    margin-top: 8px;
    margin-left: auto;
    margin-right: 10px;
`;


interface TextEditorProps {
    value: string;
    onChange: (value: string) => void;
}

const useTextEditor = (initialValue: string, onChange: (value: string) => void) => {
    const editorRef = useRef<Editor | null>(null);
    const [content, setContent] = useState(initialValue);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link,
            Underline,
            CharacterCount.configure({
                limit
            })
        ],
        content: content,
        onUpdate: ({ editor }) => {
            const html = editor.getHTML();
            setContent(html);
            onChange(html);
        },
    });

    useEffect(() => {
        const editorInstance = editorRef.current;
        if (editorInstance) {
            editorInstance.commands.setContent(initialValue);
        }
    }, [initialValue]);

    return {
        editor,
        editorRef,
    };
};

interface ToolbarProps {
    editor: Editor | null;
}

const Toolbar: FC<ToolbarProps> = ({ editor }) => {
    if (!editor) return null;

    const handleBold = () => {
        editor.chain().focus().toggleBold().run();
    };

    const handleItalic = () => {
        editor.chain().focus().toggleItalic().run();
    };

    const handleUnderline = () => {
        editor.chain().focus().toggleUnderline().run();
    };

    const handleBulletList = () => {
        editor.chain().focus().toggleBulletList().run();
    };

    const handleNumberedList = () => {
        editor.chain().focus().toggleOrderedList().run();
    };

    const handleBlockquote = () => {
        editor.chain().focus().toggleBlockquote().run();
    };

    const handleTextStyle = (selectedOption: any) => {
        const value = selectedOption?.value;
        switch (value) {
            case 'heading-small':
                editor.chain().focus().toggleHeading({ level: 3 }).run();
                break;
            case 'heading-medium':
                editor.chain().focus().toggleHeading({ level: 2 }).run()
                break;
            case 'heading-large':
                editor.chain().focus().toggleHeading({ level: 1 }).run()
                break;
            case 'normal':
                editor.chain().focus().setParagraph().run()
                break;
            default:
                editor.chain().focus().setParagraph().run()
                break;
        }
    };

    const handleUndo = () => {
        editor.chain().focus().undo().run();
    };

    const handleRedo = () => {
        editor.chain().focus().redo().run();
    };

    const textStyleOptions = [
        { value: 'normal', label: 'Normal' },
        { value: 'heading-small', label: 'Heading Small' },
        { value: 'heading-medium', label: 'Heading Medium' },
        { value: 'heading-large', label: 'Heading Large' },
    ];

    // Dropdown Styling
    const dropdownStyle: StylesConfig = {
        control: (provided: CSSObjectWithLabel) => ({
            ...provided,
            height: '32px',
            minHeight: '32px',
            width: '200px',
            cursor: 'pointer',
            textAlign: 'left',
            margin: '5px'
        }),
        menu: (provided: CSSObjectWithLabel) => ({
            ...provided,
            zIndex: 20, // Needed for Table Header
            textAlign: 'left'
        }),
        singleValue: (provided: CSSObjectWithLabel) => ({
            ...provided,
            paddingBottom: '5px' // Add paddingBottom to the selected value style
        }),
        placeholder: (provided: CSSObjectWithLabel) => ({
            ...provided,
            paddingBottom: '5px' // Add paddingBottom to the placeholder style
        })
    };

    const buttonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px'
    };

    const activeButtonStyle = {
        ...buttonStyle,
        color: '#1998d5',
    };

    return (
        <ToolbarWrapper>
            <Button
                icon={<BoldOutlined />}
                onClick={handleBold}
                style={editor.isActive('bold') ? activeButtonStyle : buttonStyle}
            />
            <Button
                icon={<ItalicOutlined />}
                onClick={handleItalic}
                style={editor.isActive('italic') ? activeButtonStyle : buttonStyle}
            />
            <Button
                icon={<UnderlineOutlined />}
                onClick={handleUnderline}
                style={editor.isActive('underline') ? activeButtonStyle : buttonStyle}
            />
            <Button
                icon={<UnorderedListOutlined />}
                onClick={handleBulletList}
                style={editor.isActive('bulletList') ? activeButtonStyle : buttonStyle}
            />
            <Button
                icon={<OrderedListOutlined />}
                onClick={handleNumberedList}
                style={editor.isActive('orderedList') ? activeButtonStyle : buttonStyle}
            />
            <Button
                icon={<ColumnWidthOutlined />}
                onClick={handleBlockquote}
                style={editor.isActive('blockquote') ? activeButtonStyle : buttonStyle}
            />
            <Select
                options={textStyleOptions}
                onChange={handleTextStyle}
                placeholder='Normal'
                styles={dropdownStyle}
            />
            <Button
                icon={<RedoOutlined />}
                onClick={handleUndo}
                style={buttonStyle}
            />
            <Button
                icon={<UndoOutlined />}
                onClick={handleRedo}
                style={buttonStyle}
            />
            <CharacterLimit>
                {editor.storage.characterCount.characters()}/{limit}
            </CharacterLimit>
        </ToolbarWrapper>
    );
};

const TextEditor: FC<TextEditorProps> = ({ value, onChange }) => {
    const { editor, editorRef } = useTextEditor(value, onChange);

    return (
        <TextEditorWrapper>
            <Toolbar editor={editor} />
            <EditorContent editor={editor} className='text-editor' />
        </TextEditorWrapper>
    );
};

export default TextEditor;