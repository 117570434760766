import { getHeaders } from '../pages/App';
import axios, { AxiosResponse } from 'axios';
import settings from '../settings.json';

// Define Prefix & OktaAuth
// @ts-ignore
const settingEnv = settings[window.location.host] || settings.default;
const {
    api_prefix: apiPrefix,
} = settingEnv;

interface GetAgentLookupParams {
    role: string;
    supervisor?: string;
    om?: string;

}

interface Agent {
    Role: string;
    Username: string;
    Email: string;
    Om?: string;
    Organization?: string;
    SeniorOm?: string;
    Site?: string;
    Supervisor?: string;
    ExpirationTimestamp: number;
}

export type EmployeePayload = Agent[];

export const getApiAgentLookup = async (lookupRole: string, user?: string, role?: string): Promise<AxiosResponse<EmployeePayload>> => {
    let params: GetAgentLookupParams = {
        role: lookupRole
    }
    if (role === 'Supervisor') {
        params.supervisor = user;
    }
    if (role === 'Om') {
        params.om = user;
    }

    const headers = await getHeaders();
    return axios.get<EmployeePayload>(apiPrefix + '/api/agent-lookup', { headers, params });
}
