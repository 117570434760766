import React from 'react';
import PageContent from '../components/layouts/PageContent';
import ringIconUrl from 'url:../images/ring_logo_white.png';
import styled from 'styled-components';

const ContentWrapper = styled.div`
    margin: 8% 0px;
    width: 100%;
    max-width: 800px;
    background-color: #0A405A;
    border-radius: 10px;
    padding: 75px 50px;
    box-shadow: 1px 1px 4px rgba(0,0,0, .2);
    align-self: center;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        font-family: equipextralight;
        font-size: 32px;
    }

    & p {
        font-family: equipextralight;
        font-size: 20px;
    }

    @media (max-width: 820px) {
        width: 80%;
        padding: 25px 10px;
        text-align: center;

        & h1 {
            font-size: 20px;
        }

        & p {
            font-size: 14px;
        }
    }
`;

const Disclaimer = styled.div`
    position: relative;
    max-width: 700px;
    bottom: 10px;
    margin: 50px auto 0px;

    font-family: equipextralight;
    font-size: 12px;
    text-align: center;
`;

const LogoImg = styled.img`
    align-self: center;
    margin: auto;
    width: 150px;
    padding: 15px;
    border-radius: 10px;

    @media (max-width: 500px) {
        width: 100px;
    }
`;

function UnauthorizedUser() {
    return (
        <PageContent
            header='Engagement Suite'
            subheader='Unauthorized User'
            footer={true}
        >
            <ContentWrapper>
                <LogoImg
                    src={ringIconUrl}
                    title='iconheader'
                />
                <h3>Unauthorized User - Engagement Suite</h3>
                <Disclaimer>
                    Access Issues? If you are unable to access the tools you need please email wfm@ring.com and request access to the EngagementSuite Okta group associated with your role:
                    (Associate, Manager, or QA)
                </Disclaimer>
            </ContentWrapper>
        </PageContent>
    );
}

export default UnauthorizedUser;
