import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

// Download Selected HTML Element as PDF (screenshot)
export const downloadElementAsPDF = async (htmlElementId: string, pdfName: string) => {
    const content = document.getElementById(htmlElementId);

    if (content) {
        const canvas = await html2canvas(content);
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape');
        const imgProperties = pdf.getImageProperties(data);

        // scale so that image width matches pdf width
        const scaledImgWidth = pdf.internal.pageSize.getWidth();
        const scaledImgHeight = imgProperties.height * (scaledImgWidth / imgProperties.width);

        let heightLeftinImg = scaledImgHeight;
        let yCoordToStartPlacingImg = 0;
        pdf.addImage(data, 'PNG', 0, yCoordToStartPlacingImg, scaledImgWidth, scaledImgHeight);

        heightLeftinImg -= pdf.internal.pageSize.getHeight()

        while (heightLeftinImg >= 0) {
            // yCoord is negative, so img starts offscreen and correct part of img is onscreen
            yCoordToStartPlacingImg = heightLeftinImg - scaledImgHeight;
            pdf.addPage()
            pdf.addImage(data, 'PNG', 0, yCoordToStartPlacingImg, scaledImgWidth, scaledImgHeight);
            heightLeftinImg -= pdf.internal.pageSize.getHeight()
        }

        pdf.save(pdfName);
    }
};
