import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EngagementSuite } from './EngagementSuite';
import { OktaAuthReact } from '@amzn/ring-cs-okta-auth-client';
import UnauthorizedUser from './UnauthorizedUser';
import settings from '../settings.json';
// Styling Imports
import 'antd/dist/reset.css';
import '../components/layouts/font-face.css';
import '../components/layouts/base.css';

// prep this site to use Okta
const { okta: { iss, cid } } = (settings as any)[window.location.host] || settings.default;
export const {
    ContinuousAuthProvider,
    useAuthState,
    useContinuousAuth,
    login,
    logout,
    getHeaders,
} = new OktaAuthReact(iss, cid);

function App() {
    return (
        <Routes>
			<Route path='/' element={<EngagementSuite />} />
			<Route path='/unauthorized' element={<UnauthorizedUser />} />
		</Routes>
    );
}

export default App;