import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Elements
import { Button, Checkbox, Card, Col, DatePicker, Divider, Input, message, Modal, Row, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, DeleteTwoTone, DownloadOutlined, EditOutlined, FlagTwoTone, SearchOutlined, ExclamationCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import HtmlRenderer from '../../tiptap/HtmlRenderer';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { downloadElementAsPDF } from '../../common/DownloadUtils';
import { CSVLink } from 'react-csv';

// Variables
import { useContinuousAuth } from '../../pages/App';
import settings from '../../settings.json';
import { getApiAgentLookup } from '../../common/APIUtils';

// Set API Prefix & OktaAuth
const settingEnv = settings[window.location.host] || settings.default;
const {
    api_prefix: apiPrefix,
    cstools: { enableCsqaIntegration },
} = settingEnv;

// Date Picker (set current & due date)
const { RangePicker } = DatePicker;

// Edit Review Options
const editOptions = [
    'Dispute',
    'Reviewer Correction',
    'Scorecard Audit',
];

// Styled Components
const DashboardBody = styled.div`
    height: 100vh;
    background-color: #f9f9f9;
    padding: 1% 2%;
`;

const DashboardTable = styled.div`
    margin-top: 20px;

    & table {
        border: 1px solid #f0f0f0;
    }

    & td, th {
        font-size: 14px !important;
    }

`;
// Expanded Row Components
const ReviewDetails = styled.div`
`;

const ReviewLabel = styled.p`
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
`;

const ReviewValue = styled.p`
    font-size: 14px;
    margin-bottom: 5px;
`;

const ReviewRow = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

const ReviewColumn = styled.div`
    flex: 50%;
    padding-left: 5px;
    padding-right: 5px;
`;

const SectionSubHeader = styled.p`
    color: #1998d5;
    font-size: 18px;
    text-align: left;
`;

const SectionDetails = styled.p`
`;

const SectionLabel = styled.span`
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
`;

const SectionValue = styled.span`
    font-size: 14px;
    margin-bottom: 5px;
`;

const SectionNotes = styled.div`
`;

const ExpandedRowHeaderIcons = styled.div`
`;
// Edit Modal Components
const EditCSQAWrapper = styled.div`
    margin-top: -20px;

    & .ant-divider-horizontal:before {
        top: 0% !important;
    }

    & .ant-divider-horizontal:after {
        top: 0% !important;
    }
`;

const EditCsqaDescriptionText = styled.p`
    font-size: 12px;
    margin-bottom: 2px;
    margin-top: 10px;
`;

const NotesPreview = styled.div`
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 2%;
    max-height: 250px;
    overflow-y: scroll;
    margin-bottom: 20px;
`;

// Dropdown Styling
const dropdownStyle = {
    control: (provided) => ({
        ...provided,
        height: '42.5px',
        minHeight: '42.5px',
        cursor: 'pointer',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 20, // Needed for Table Header
    }),
};

// Table Column Search/Sort Functionality
function ColumnSearch(props) {
    const {
        dataIndex,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
        setSearchText,
        setSearchedColumn,
    } = props;

    const inputRef = useRef();

    useEffect(() => {
        if (visible && inputRef.current) {
            setTimeout(() => inputRef.current.select(), 100);
        }
    }, [visible, inputRef]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    return (
        <div style={{ padding: 8 }}>
            <Input
                ref={inputRef}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    danger
                    onClick={() => handleReset(clearFilters)}
                    size='small'
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    );
}

// CSQA Dashboard
function CsqaDashboard(props) {
    const { userPermissions } = props;
    // Export/Table vars
    const [maxCount, setMaxCount] = useState(false);
    const [exportReviews, setExportReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    // Date vars
    const [date, setDate] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [value, setValue] = useState('');
    // Search vars
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchText, setSearchText] = useState('');
    // Filter vars
    const [agent, setAgent] = useState('');
    const [agentList, setAgentList] = useState([]);
    const [coachedBy, setCoachedBy] = useState('');
    const [site, setSite] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [supervisorList, setSupervisorList] = useState([]);
    // Edit vars
    const [editReviewDetails, setEditReviewDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { getHeaders } = useContinuousAuth();

    // Onload | Fetch Employees
    useEffect(() => {
        if (userPermissions.has('createCsqa')) {
            fetchAgents();
            fetchSupervisors();
        }
    }, [userPermissions]);
    // Onload | Fetch Reviews
    useEffect(() => {
        setLoading(true);
        fetchReviews()
            .then(coachings => {
                setExportReviews(coachings.payload);
                // Results Check
                setMaxCount(!!coachings.maxResults);
            })
            .catch(e => {
                message.error('There was a problem with your Request');
                console.error('There was a problem with your Request', e);
            })
            .finally(() => setLoading(false));
    }, []);

    // Fetch Coaching Details
    const fetchReviews = async (startDate, endDate, agent, supervisor, coachedBy, site) => {
        try {
            const params = {
                ...((startDate && endDate) && { startDate, endDate }),
                ...(agent && { agentFilter: agent }),
                ...(supervisor && { supervisorFilter: supervisor }),
                ...(coachedBy && { qaUserFilter: coachedBy }),
                ...(site && { siteFilter: site }),
            };
            const headers = await getHeaders();
            const { data } = await axios.get(apiPrefix + '/api/csqa', { headers, params });
            return data;
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error('There was a problem with your review fetch', e);
        }
    };

    // Fetch | Reviews (Filters)
    const reviewFetch = async (startDate, endDate, agent, supervisor, coachedBy, site) => {
        setLoading(true);
        try {
            const results = await fetchReviews(startDate, endDate, agent, supervisor, coachedBy, site);
            setExportReviews(results.payload);
            // Results Check
            setMaxCount(!!results.maxResults);
        } catch (e) {
            message.error('There was a problem with your Filter Request');
            console.error('There was a problem with your Filter Request', e);
        } finally {
            setLoading(false);
        }
    };

    // Fetch | Update/Edit Review
    const updateReview = async (request) => {
        setLoading(true);
        const headers = await getHeaders();
        try {
            await axios.post(apiPrefix + '/api/csqa', request, { headers });
            Swal.fire({
                title: 'Success!',
                text: 'Thank you for your submission. Your Changes are now showing in the Dashboard.',
                icon: 'success',
            });
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };
    // Fetch | Employees (Agents)
    const fetchAgents = async () => {
        try {
            const agents = await getApiAgentLookup('Agent')
            setAgentList(agents.data);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error('There was a problem retreiving agent list', e);
        }
    };
    // Fetch | Employees (Supervisors)
    const fetchSupervisors = async () => {
        try {
            const supervisors = await getApiAgentLookup('Supervisor');
            setSupervisorList(supervisors.data);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error('There was a problem retreiving supervisor list', e);
        }
    };
    // Sort | Reviewed By List
    const coachedByList = useMemo(() => {
        const users = exportReviews.map((item) => item.user);
        return [...new Set(users).values()].sort();
    }, [exportReviews]);
    // Sort | Site List
    const siteList = useMemo(() => {
        const sites = agentList.map((item) => item.Site);
        return [...new Set(sites).values()].sort();
    }, [agentList]);


    // Filter | Date Picker (date change, set disabled dates, set date)
    const onDateChange = (value, dateString) => {
        setValue(value);
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };
    const disabledDate = current => {
        if (!date || date.length === 0) {
            return false;
        }
        const tooLate = date[0] && current.diff(date[0], 'days') > 180;
        const tooEarly = date[1] && date[1].diff(current, 'days') > 0;
        return tooEarly || tooLate;
    };
    const onOpenChange = open => {
        if (open) {
            setDate([]);
        }
    };


    // Export | Generate Reviews CSV Content for Download
    const generateExportReviewsCsvContent = () => {
        const exportReviewsCsv = exportReviews.map((row) => {
            let newRow = { ...row };
            let rowKeys = Object.keys(row);

            rowKeys.map((key, _index) => {
                if (key.includes("redflag")) {
                    newRow[key] =
                        row[key]?.checked === true ? "Knowledge Check" : "N/A";
                } else if (row[key].checked != null) {
                    newRow[key] =
                        row[key]?.checked === true ? "Yes" : "Knowledge Check";
                }
            });

            delete newRow.notes;
            return newRow;
        });
        return exportReviewsCsv;
    };
    // Export | Define CSV Content
    const exportReviewsCsvContent = useMemo(() => generateExportReviewsCsvContent(), [exportReviews]);
    // Export | Define Filename
    const reviewsCsvFilename = useMemo(() => {
        const curDate = new Date();
        const csvFilename = `CSQA_Export_${(curDate.getMonth() + 1).toString().padStart(2, '0')}_${curDate.getDate().toString().padStart(2, '0')}_${curDate.getFullYear()}.csv`;
        return csvFilename;
    }, [exportReviews]);

    // Table | Define Column Search Properties
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: (props) => (
            <ColumnSearch
                {...{
                    ...props,
                    dataIndex,
                    setSearchText,
                    setSearchedColumn,
                }}
            />
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    // Table | Define Columns
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                multiple: 1,
            },
        },
        {
            title: 'Agent',
            dataIndex: 'agent',
            ...getColumnSearchProps('agent'),
            sorter: {
                compare: (a, b) => a.agent.localeCompare(b.agent),
                multiple: 2,
            },
        },
        {
            title: 'Supervisor',
            dataIndex: 'supervisor',
            ...getColumnSearchProps('supervisor'),
            // sorter: true
        },
        {
            title: 'Review Type',
            dataIndex: 'review',
            onFilter: (value, record) => record.review.indexOf(value) === 0,
            // sorter: true,
        },
        {
            title: 'Form ID',
            dataIndex: 'formId',
            ...getColumnSearchProps('formId'),
            // sorter: true,
        },
        {
            title: 'Kustomer Link',
            dataIndex: 'kustomerURL',
            ...getColumnSearchProps('kustomerURL'),
            render(record, _index) {
                let shortenedURL = record ? record : "n/a";
                if (shortenedURL.length > 24) {
                    shortenedURL = shortenedURL.substring(0, 24);
                }
                return (
                    <>
                        <a href={record ? record : ""}>{shortenedURL}</a>
                    </>
                );
            }
        },
        // Column - Red Flag Indicator
        {
            title: 'Red Flag',
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value, record) => value === !!Object.keys(record).find(key => key.includes('redflag') && record[key].checked),
            render(record, row) {
                if (row.deleted) {
                    return <Tooltip
                        color='#fff'
                        overlayInnerStyle={{
                            color: 'red'
                        }}
                        title='Review Deleted'
                    >
                        <DeleteTwoTone twoToneColor="red" />
                    </Tooltip>;
                }
                if (Object.keys(row).find(key => key.includes('redflag') && row[key].checked)) {
                    return <Tooltip
                        color='#fff'
                        overlayInnerStyle={{
                            color: 'red'
                        }}
                        title='Red Flag Identified'
                    >
                        <FlagTwoTone twoToneColor="red" />
                    </Tooltip>;
                }

                return null;
            }
        },
        // Column - Coaching Status
        {
            title: 'Status',
            filters: [
                { text: 'N/A', value: 'n/a' },
                { text: 'Required', value: 'required' },
                { text: 'Complete', value: 'complete' },
            ],
            width: enableCsqaIntegration ? 'auto' : '0px',
            ellipsis: enableCsqaIntegration ? false : true,
            onFilter: (value, record) => value === record.coachingStatus,
            render(record, row) {
                switch (row.coachingStatus) {
                    case 'n/a':
                        return null;
                    case 'required':
                        return <Tooltip
                            color='#fff'
                            overlayInnerStyle={{
                                color: '#F1670D'
                            }}
                            title='Coaching Required'
                        >
                            <ExclamationCircleTwoTone twoToneColor='#F1670D' />
                        </Tooltip>;
                    case 'complete':
                        return <Tooltip
                            color='#fff'
                            overlayInnerStyle={{
                                color: '#1998d5'
                            }}
                            title='Coaching Complete'
                        >
                            <CheckCircleTwoTone twoToneColor='#1998d5' />
                        </Tooltip>;
                    default:
                        return null;
                }
            }
        },
    ];
    // Table | Derived Columns for Column Filters
    const derivedColumn = columns.map((col, index) => {
        if (index === 3) {
            col.filters = (exportReviews || null).map((row) => ({
                text: row.review,
                value: row.review
            })).filter((item, index, self) => index === self.findIndex((it) => item.text === it.text));
        }
        return col;
    });
    // Table | Expanded Row Component
    const expandedRowRender = (event) => {
        // Edit Review | Show Modal
        const showModal = async () => {
            setEditReviewDetails(event);
            setIsModalOpen(true);

        };
        // Edit Review | Confirm Click
        const confirmClick = async () => {
            setIsModalOpen(false);
            if (!editReviewDetails.editReason) {
                return message.error('Please add Edit Reason');
            }
            const payload = {
                doc: editReviewDetails,
                id: editReviewDetails.id,
            };
            updateReview(payload);

        };
        // Edit Review | Cancel Click
        const cancelClick = async () => {
            setEditReviewDetails({});
            setIsModalOpen(false);
        };
        // Delete Review | Button Click
        const deleteClick = async () => {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Selecting confirm below will Delete this Review from the Dashboard',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    event.deleted = true;
                    const payload = {
                        doc: event,
                        id: event.id,
                    };
                    updateReview(payload);
                }
            });
        };

        //Expanded Row Component
        return (
            <Card
                title="Review Details"
                headStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
                id={`reviewDetails${event.formId}`}
                extra={!event.deleted &&
                    <ExpandedRowHeaderIcons>
                        <Button
                            type='primary'
                            shape='round'
                            icon={
                                <DownloadOutlined />
                            }
                            onClick={() => {
                                downloadElementAsPDF(
                                    `reviewDetails${event.formId}`,
                                    `review-details-${event.formId}.pdf`
                                );
                            }}
                            className='review-details-download-button'
                        >
                            Download Review Details
                        </Button>
                        {/* Edit Ability only available to editRoles */}
                        {userPermissions.has('editCsqa') &&
                            <Button
                                type='default'
                                shape='round'
                                icon={
                                    <EditOutlined />
                                }
                                onClick={showModal}
                                className='review-details-download-button'
                                style={{
                                    marginLeft: '10px'
                                }}
                            >
                                Edit Review Details
                            </Button>
                        }
                        {userPermissions.has('deleteCsqa') &&
                            <Button
                                type='danger'
                                shape='round'
                                icon={
                                    <DeleteOutlined />
                                }
                                onClick={deleteClick}
                                className='review-details-download-button'
                                style={{
                                    marginLeft: '10px'
                                }}
                            >
                                Delete Review
                            </Button>
                        }
                    </ExpandedRowHeaderIcons>
                }

            >
                <ReviewDetails>
                    <ReviewLabel> Type: </ReviewLabel>
                    <ReviewValue> {event.review} </ReviewValue>
                    <ReviewLabel> Agent Associated: </ReviewLabel>
                    <ReviewValue> {event.agent} </ReviewValue>
                    <ReviewLabel> Supervisor Associated: </ReviewLabel>
                    <ReviewValue> {event.supervisor} </ReviewValue>
                    <ReviewLabel> Kustomer URL: </ReviewLabel>
                    <ReviewValue> <a href={event.kustomerURL} target="_blank">{event.kustomerURL}</a></ReviewValue>
                    <ReviewLabel> Date: </ReviewLabel>
                    <ReviewValue> {event.date} </ReviewValue>
                    <Divider />
                    <ReviewRow>
                        <ReviewColumn>
                            <SectionSubHeader> Call Flow </SectionSubHeader>
                            {Object.keys(event).filter((key) => key.includes('callflow')).map((key, index) =>
                                <SectionDetails key={index}>
                                    <SectionLabel>{event[key]?.label || 'N/A'}</SectionLabel>
                                    <SectionValue>{event[key]?.checked === true ? 'Yes' : 'Knowledge Check'}</SectionValue>
                                </SectionDetails>
                            )}
                        </ReviewColumn>
                        <ReviewColumn>
                            <SectionSubHeader> Soft Skills </SectionSubHeader>
                            {Object.keys(event).filter((key) => key.includes('softskill')).map((key, index) =>
                                <SectionDetails key={index}>
                                    <SectionLabel>{event[key]?.label || 'N/A'}</SectionLabel>
                                    <SectionValue>{event[key]?.checked === true ? 'Yes' : 'Knowledge Check'}</SectionValue>
                                </SectionDetails>
                            )}
                        </ReviewColumn>
                        <ReviewColumn>
                            <SectionSubHeader> Team Specific Workflow </SectionSubHeader>
                            {Object.keys(event).filter((key) => key.includes('neighborimpacting')).map((key, index) =>
                                <SectionDetails key={index}>
                                    <SectionLabel>{event[key]?.label || 'N/A'}</SectionLabel>
                                    <SectionValue>{event[key]?.checked === true ? 'Yes' : 'Knowledge Check'}</SectionValue>
                                </SectionDetails>
                            )}
                        </ReviewColumn>
                    </ReviewRow>
                    <Divider />
                    <ReviewRow>
                        <ReviewColumn>
                            <SectionSubHeader> Red Flags </SectionSubHeader>
                            {Object.keys(event).filter((key) => key.includes('redflag')).map((key, index) =>
                                <SectionDetails key={index}>
                                    <SectionLabel>{event[key]?.label || 'N/A'}</SectionLabel>
                                    <SectionValue>{event[key]?.checked === true ? 'Knowledge Check' : 'N/A'}</SectionValue>
                                </SectionDetails>
                            )}
                        </ReviewColumn>
                        <ReviewColumn>
                            <SectionSubHeader> Notes </SectionSubHeader>
                            <SectionNotes>
                                <HtmlRenderer html={event.notes} />
                            </SectionNotes>
                        </ReviewColumn>
                    </ReviewRow>
                </ReviewDetails>
                {/* Edit CSQA Modal */}
                <Modal
                    title='Edit CSQA Review Details'
                    open={isModalOpen}
                    onOk={confirmClick}
                    onCancel={cancelClick}
                    okText='Save'
                    width={900}
                    closeIcon={<></>}
                    bodyStyle={{
                        backgroundColor: '#F6F8FA',
                        height: '600px',
                        overflow: 'scroll'
                    }}
                    maskClosable={false}
                    maskStyle={{
                        backgroundColor: '#EFF8FC'

                    }}
                    style={{
                        marginTop: '-50px'
                    }}
                >
                    {editCsqaModal(event)}
                </Modal>
            </Card>
        );
    };

    // Edit Review | Modal Component
    const editCsqaModal = () => {
        // Set Team Member Details based on Agent Selection
        const setTmDetails = (selectedOption) => {
            const value = selectedOption.value;
            editReviewDetails.agent = value;
            editReviewDetails.om = agentList.find((x) => x.Username === value).Om;
            editReviewDetails.seniorOm = agentList.find((x) => x.Username === value).SeniorOm;
            editReviewDetails.site = agentList.find((x) => x.Username === value).Site;
            editReviewDetails.supervisor = agentList.find((x) => x.Username === value).Supervisor;
        };

        const editOptionsList = editOptions?.map((reason) => ({ value: reason, label: reason }));
        const agentListOptions = agentList?.map((agent) => ({ value: agent.Username, label: agent.Username }));

        return (
            <EditCSQAWrapper>
                <Row>
                    {/* Field | Edit Reason */}
                    <Col
                        style={{
                            padding: '0px 5px',
                        }}
                        span={10}
                    >
                        <EditCsqaDescriptionText> Reason: </EditCsqaDescriptionText>
                        <Select
                            options={editOptionsList}
                            onChange={(selectedOption) => {
                                editReviewDetails.editReason = selectedOption.value;
                            }}
                            placeholder='Edit Reason'
                            styles={dropdownStyle}
                        />
                    </Col>
                    {/* Field | Kustomer URL */}
                    <Col
                        span={11}
                        offset={2}
                        style={{
                            padding: '0px 5px',
                        }}
                    >
                        <EditCsqaDescriptionText> URL Associated: </EditCsqaDescriptionText>
                        <Input
                            placeholder='Kustomer URL'
                            onChange={(value) => (editReviewDetails.kustomerURL = value.target.value)}
                            value={editReviewDetails.kustomerURL}
                            style={{
                                borderRadius: '5px',
                                fontSize: '18px',
                                height: '42.5px',
                                width: '100%',
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    {/* Field | Date Picker */}
                    <Col
                        style={{
                            padding: '0px 5px',
                        }}
                        span={10}
                    >
                        <EditCsqaDescriptionText> Date Associated: </EditCsqaDescriptionText>
                        <DatePicker
                            name='date'
                            disabledDate={disabledDate}
                            onChange={(date, dateString) => (editReviewDetails.date = dateString)}
                            size='large'
                            style={{
                                borderRadius: '5px',
                                fontSize: '18px',
                                height: '42.5px',
                                width: '100%',
                            }}
                            defaultValue={moment()}
                            placeholder='Select Date'
                        />
                    </Col>
                    {/* Field | Agent Selection */}
                    <Col
                        span={11}
                        offset={2}
                        style={{
                            padding: '0px 5px',
                        }}
                    >
                        <EditCsqaDescriptionText> Agent Associated: </EditCsqaDescriptionText>
                        <Select
                            options={agentListOptions}
                            onChange={setTmDetails}
                            value={agentListOptions.find((option) => option.value === editReviewDetails.agent) || ''}
                            styles={dropdownStyle}
                        />
                    </Col>
                </Row>
                {/* Field | Checkboxes */}
                <Row
                    style={{
                        padding: '0px 5px',
                    }}
                    span={24}
                >
                    <Divider
                        orientation="left"
                        style={{
                            color: '#69bce3',
                            fontSize: '18px',
                            fontFamily: 'equipmedium',
                            padding: '0px 10px',
                            top: '0%'
                        }}
                    > Checkbox Selections
                    </Divider>
                    {
                        Object.keys(editReviewDetails).map((key, _index) => {
                            if (editReviewDetails[key].hasOwnProperty('checked')) {
                                return (
                                    <Col
                                        span={12}
                                    >
                                        <Checkbox
                                            defaultChecked={editReviewDetails[key].checked}
                                            onChange={(e) => {
                                                editReviewDetails[key].checked = e.target.checked;
                                            }}
                                            style={{
                                                margin: '8px 0px'
                                            }}

                                        >
                                            {editReviewDetails[key].label}
                                        </Checkbox>
                                    </Col>
                                );
                            }
                        })
                    }
                </Row>
                {/* Field | Notes */}
                <Row>
                    <Col
                        style={{
                            padding: '0px 5px',
                        }}
                        span={24}
                    >
                        <Divider
                            orientation="left"
                            style={{
                                color: '#69bce3',
                                fontSize: '18px',
                                fontFamily: 'equipmedium',
                                padding: '0px 10px',
                                top: '0%'
                            }}
                        > Notes/Feedback
                        </Divider>
                        <NotesPreview>
                            <HtmlRenderer html={editReviewDetails.notes || 'N/A'} />
                        </NotesPreview>
                    </Col>
                </Row>
            </EditCSQAWrapper>
        );
    };

    // Dropdown Options
    const agentListOptions = agentList?.map((agent) => ({ value: agent.Username, label: agent.Username }));
    const coachedByListOptions = coachedByList?.map((user) => ({ value: user, label: user }));
    const supervisorListOptions = supervisorList?.map((supervisor) => ({ value: supervisor.Username, label: supervisor.Username }));
    const siteListOptions = siteList?.map((site) => ({ value: site, label: site }));

    // CSQA Dashboard
    return (
        <DashboardBody>
            {/* Filter Body (Date Picker, Agent, Supervisor, Site, QA User) */}
            <Card title='Filters' bodyStyle={{ padding: '24px 24px 12px' }} style={{ borderTop: 'inherit' }}>
                <Row type='flex' gutter={8}>
                    <Col>
                        <RangePicker
                            className='coaching-dashboard-date'
                            onChange={onDateChange}
                            value={value || []}
                            disabledDate={disabledDate}
                            onCalendarChange={(value) => { setDate(value); }}
                            onOpenChange={onOpenChange}
                        />
                        <p className='coaching-description muted-text'>
                            Default Range | Last 30 Days <br />
                            {maxCount &&
                                <span style={{ color: '#FF0000' }}>
                                    Max Results Displayed, please consider reducing your Date Range
                                </span>
                            }
                        </p>
                    </Col>
                    {/* Filter Options only availabe to users who match searchRoles */}
                    {(userPermissions.has('searchCsqa') || userPermissions.has('siteSearchCsqa')) && <>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 8 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={agentListOptions}
                                onChange={(selectedOption) => {
                                    setAgent(selectedOption.value);
                                    setSite('');
                                    setSupervisor('');
                                    setCoachedBy('');
                                }}
                                value={agentListOptions.find((option) => option.value === agent) || ''}
                                placeholder='Filter by Agent'
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 8 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={supervisorListOptions}
                                onChange={(selectedOption) => {
                                    setAgent('');
                                    setSite('');
                                    setSupervisor(selectedOption.value);
                                    setCoachedBy('');
                                }}
                                value={supervisorListOptions.find((option) => option.value === supervisor) || ''}
                                placeholder='Filter by Supervisor'
                                styles={dropdownStyle}
                            />
                        </Col>
                        {userPermissions.has('searchCsqa') &&
                            <Col
                                xs={{ span: 20 }}
                                sm={{ span: 8 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 4 }}
                            >
                                <Select
                                    options={siteListOptions}
                                    onChange={(selectedOption) => {
                                        setAgent('');
                                        setSite(selectedOption.value);
                                        setSupervisor('');
                                        setCoachedBy('');
                                    }}
                                    value={siteListOptions.find((option) => option.value === site) || ''}
                                    placeholder='Filter by Site'
                                    styles={dropdownStyle}
                                />
                            </Col>
                        }
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 8 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={coachedByListOptions}
                                onChange={(selectedOption) => {
                                    setAgent('');
                                    setSite('');
                                    setSupervisor('');
                                    setCoachedBy(selectedOption.value);
                                }}
                                value={coachedByListOptions.find((option) => option.value === coachedBy) || ''}
                                placeholder='Filter by CSQA Agent'
                                styles={dropdownStyle}
                            />
                        </Col>
                    </>}
                </Row>
            </Card>
            {/* Filter Footer Menu */}
            <Card bodyStyle={{ padding: '12px 24px 24px' }} style={{ borderTop: 'inherit' }}>
                <Row justify='end' type='flex'>
                    <Col>
                        {exportReviews.length > 0 &&
                            <CSVLink
                                filename={reviewsCsvFilename}
                                data={exportReviewsCsvContent}
                                className='csv-export coaching-dashboard-button ant-btn ant-btn-primary'
                            >
                                Export to CSV
                            </CSVLink>
                        }
                        <Button
                            danger
                            disabled=''
                            onClick={() => {
                                reviewFetch();
                                setAgent('');
                                setCoachedBy('');
                                setSite('');
                                setSupervisor('');
                                setValue('');
                            }}
                            style={{
                                borderRadius: '5px !important',
                                marginRight: '10px'
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            type='primary'
                            disabled=''
                            onClick={() => {
                                reviewFetch(startDate, endDate, agent, supervisor, coachedBy, site);
                            }}
                            style={{
                                borderRadius: '5px !important'
                            }}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Card>
            {/* Table Component */}
            <DashboardTable>
                <Table
                    columns={derivedColumn}
                    loading={loading}
                    rowKey={record => record.formId}
                    expandable={{
                        expandedRowRender: expandedRowRender,
                        rowExpandable: record => record.requestId !== 'Not Expandable',
                        expandedRowKeys: expandedRowKeys,
                        expandRowByClick: true,
                        onExpand: (expanded, record) => {
                            const keys = [];
                            if (expanded) {
                                keys.push(record.formId);
                            }
                            setExpandedRowKeys(keys);
                        }
                    }}
                    dataSource={exportReviews || null}
                />
            </DashboardTable>
        </DashboardBody>
    );

}

export default CsqaDashboard;
