import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { getApiAgentLookup } from '../../common/APIUtils';

//Elements
import Swal from 'sweetalert2';
import { Button, Input, Card, Row, Col, Space, DatePicker, Table, message, Modal, Statistic, Tabs, Divider } from 'antd';
import { CheckOutlined, ClockCircleOutlined, DeleteOutlined, DownloadOutlined, FileSearchOutlined, LikeOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import HtmlRenderer from '../../tiptap/HtmlRenderer';
import TextEditor from '../../tiptap/TipTap';
import Select from 'react-select';
import moment from 'moment';

//CSS
import './coaching-corner.css';

//Variables
import { useContinuousAuth } from '../../pages/App';
import settings from '../../settings.json';

// Set API Prefix & OktaAuth
const settingEnv = settings[window.location.host] || settings.default;
const {
    api_prefix: apiPrefix,
    cstools: { enableCsqaIntegration },
} = settingEnv;

// Rich Text Editor (remove toolbar and set default value)
const toolbarConfig = { display: [] };
const defaultEditorValue = '<p><br></p>';

// Date Picker (set current & due date)
const { RangePicker } = DatePicker;
const currentDate = `${moment().format('YYYY-MM-DD')}`;
const dueDate = `${moment().add(5, 'days').format('YYYY-MM-DD')}`;

// Table Column Search/Sort Functionality
function ColumnSearch(props) {
    const {
        dataIndex,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
        setSearchText,
        setSearchedColumn,
    } = props;

    const inputRef = useRef();

    useEffect(() => {
        if (visible && inputRef.current) {
            setTimeout(() => inputRef.current.select(), 100);
        }
    }, [visible, inputRef]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    return (
        <div style={{ padding: 8 }}>
            <Input
                ref={inputRef}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    danger
                    onClick={() => handleReset(clearFilters)}
                    size='small'
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    );
}

// By the Numbers
function ByTheNumbersComponent(props) {
    const {
        exportCoachings,
        userPermissions
    } = props;

    // By the Numbers vars
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Define by the numbers counts
    const actionCount = {};
    const categoryCount = {};
    const statusCount = {};
    const reasonCount = {};

    let creationToReadyForDeliveryTimeInfo = {
        count: 0,
        sum: 0
    };
    let pendingToReworkTimeInfo = {
        count: 0,
        sum: 0
    };
    let reworkToPendingTimeInfo = {
        count: 0,
        sum: 0
    };
    let pendingToReadyForDeliveryTimeInfo = {
        count: 0,
        sum: 0
    };
    let readyForDeliveryToDeliveryTimeInfo = {
        count: 0,
        sum: 0
    };

    const incrementItem = (obj, item) => {
        if (!obj[item]) {
            obj[item] = 0;
        }
        obj[item] += 1;
    };

    const addToTimeInfoObj = (timeInfoObj, time) => {
        timeInfoObj.count += 1;
        timeInfoObj.sum += time;
    };

    const getTimeString = (num, unit) => {
        if (num === 0) {
            return '';
        } else if (num === 1) {
            return `${num} ${unit}`;
        } else {
            return `${num} ${unit}s`;
        }
    };

    const getAverageDurationString = (timeInfoObj) => {
        if (timeInfoObj.count <= 0) { return 'N/A'; }
        let duration = moment.duration(
            Math.round(timeInfoObj.sum / timeInfoObj.count));

        const days = duration.days();
        const daysString = getTimeString(days, 'day');
        duration.subtract(moment.duration(days, 'days'));
        const hours = duration.hours();
        const hoursString = getTimeString(hours, 'hour');
        duration.subtract(moment.duration(hours, 'hours'));
        const mins = duration.minutes();
        const minsString = getTimeString(mins, 'min');
        duration.subtract(moment.duration(mins, 'minutes'));

        const finalString = (daysString.length ? `${daysString}, ` : '') +
            (hoursString.length ? `${hoursString}, ` : '') +
            (minsString.length ? `${minsString}` : '');
        return finalString.length ? finalString : '<1 min';
    };

    for (const item of exportCoachings) {
        incrementItem(actionCount, item.action);
        incrementItem(categoryCount, item.category);
        incrementItem(statusCount, item.status);
        incrementItem(reasonCount, item.reason);
        if (item.creationToReadyForDeliveryTime) {
            addToTimeInfoObj(creationToReadyForDeliveryTimeInfo, item.creationToReadyForDeliveryTime);
        }
        if (item.pendingToReworkTime) {
            addToTimeInfoObj(pendingToReworkTimeInfo, item.pendingToReworkTime);
        }
        if (item.reworkToPendingTime) {
            addToTimeInfoObj(reworkToPendingTimeInfo, item.reworkToPendingTime);
        }
        if (item.pendingToReadyForDeliveryTime) {
            addToTimeInfoObj(pendingToReadyForDeliveryTimeInfo, item.pendingToReadyForDeliveryTime);
        }
        if (item.readyForDeliveryToDeliveryTime) {
            addToTimeInfoObj(readyForDeliveryToDeliveryTimeInfo, item.readyForDeliveryToDeliveryTime);
        }
    }

    const turnoverAveragesBoth = {
        'Average Approval Time':
            getAverageDurationString(creationToReadyForDeliveryTimeInfo)
    };

    const turnoverAveragesHR = {
        'Rework (HR)':
            getAverageDurationString(pendingToReworkTimeInfo),
        'Approved (HR)':
            getAverageDurationString(pendingToReadyForDeliveryTimeInfo),

    };

    const turnoverAveragesCS = {
        'Rework (CS)':
            getAverageDurationString(reworkToPendingTimeInfo),
        'Delivered (CS)':
            getAverageDurationString(readyForDeliveryToDeliveryTimeInfo)
    };

    return (
        <>
            <Button
                type='primary'
                icon={
                    <FileSearchOutlined />
                }
                onClick={() => {
                    setIsModalOpen((isModalOpen) => !isModalOpen);
                }}
                className='coaching-dashboard-button'
                style={{
                    position: 'absolute',
                    left: '25px',
                }}
            >
                By the Numbers
            </Button>
            <Modal
                title='By The Numbers'
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    setIsModalOpen((isModalOpen) => !isModalOpen);
                }}
                bodyStyle={{
                    padding: '10px'
                }}
            >
                <Tabs
                    defaultActiveKey="1"
                    tabPosition='left'
                    style={{
                        height: 350,
                    }}
                >
                    <Tabs.TabPane
                        tab="Totals"
                        key="1"
                        style={{
                            height: 350,
                            overflow: 'scroll'
                        }}
                    >
                        <Statistic title='Total Submissions' value={exportCoachings.length} />
                        {Object.entries(statusCount).map(([key, value]) =>
                            <Statistic title={key} value={value} />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Category Breakdown"
                        key="2"
                        style={{
                            height: 350,
                            overflow: 'scroll'
                        }}
                    >
                        {Object.entries(categoryCount).map(([key, value]) =>
                            <Statistic title={key} value={value} />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Reason Breakdown"
                        key="3"
                        style={{
                            height: 350,
                            overflow: 'scroll'
                        }}
                    >
                        {Object.entries(reasonCount).map(([key, value]) =>
                            <Statistic title={key} value={value} />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Action Breakdown"
                        key="4"
                        style={{
                            height: 350,
                            overflow: 'scroll'
                        }}
                    >
                        {Object.entries(actionCount).map(([key, value]) =>
                            <Statistic title={key} value={value} />
                        )}
                    </Tabs.TabPane>
                    {userPermissions.has('adminViewCoaching') &&
                        <Tabs.TabPane tab="Turnover Breakdown" key="5">
                            <>
                                {Object.entries(turnoverAveragesBoth).map(([key, value]) =>
                                    <Statistic title={key} value={value} valueStyle={{ fontSize: '18px' }} />
                                )}
                                <Divider orientation="left" style={{ color: '#1890ff', fontSize: '14px' }}>Stage Breakdown</Divider>
                                {Object.entries(turnoverAveragesHR).map(([key, value]) =>
                                    <Statistic title={key} value={value} valueStyle={{ color: '#10628A', fontSize: '18px' }} />
                                )}
                                {Object.entries(turnoverAveragesCS).map(([key, value]) =>
                                    <Statistic title={key} value={value} valueStyle={{ color: '#9C4308', fontSize: '18px' }} />
                                )}
                            </>
                        </Tabs.TabPane>
                    }
                </Tabs>
            </Modal>
        </>
    );
}

// Coaching Dashboard Component
function CoachingDashboard(props) {
    const {
        user,
        tempRole,
        userPermissions
    } = props;

    const [maxCount, setMaxCount] = useState(false);
    const [exportCoachings, setExportCoachings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRowIndicator, setExpandedRowIndicator] = useState([]);
    //Rework vars
    const [isReworkVisible, setIsReworkVisible] = useState(false);
    const [isCsReworkVisible, setIsCsReworkVisible] = useState(false);
    const [hrNotes, setHrNotes] = useState('');
    const [hrFlag, setHrFlag] = useState(false);
    const [notes, setNotes] = useState('');
    const [notesFlag, setNotesFlag] = useState(false);
    // Date vars
    const [date, setDate] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [value, setValue] = useState('');
    // Search vars
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchText, setSearchText] = useState('');
    // Filter vars
    const [agent, setAgent] = useState('');
    const [agentList, setAgentList] = useState([]);
    const [coachedBy, setCoachedBy] = useState('');
    const [coachedByList, setCoachedByList] = useState([]);
    const [supervisor, setSupervisor] = useState('');
    const [supervisorList, setSupervisorList] = useState([]);

    const { getHeaders } = useContinuousAuth();

    // Fetch Coaching Payload (On Load)
    useEffect(() => {
        setLoading(true);
        fetchCoaching(userPermissions, tempRole, user)
            .then(coachings => {
                console.log('Temp Role?', tempRole);
                setExportCoachings(coachings.payload);
                // Results Check
                setMaxCount(!!coachings.maxResults);
                trackSearch();
            })
            .catch(e => {
                message.error('There was a problem with your Request');
                console.error(e.message);
            })
            .finally(() => setLoading(false));
    }, [user, tempRole]);

    // Fetch Employees (On Load)
    useEffect(() => {
        if (userPermissions.has('searchCoaching')) {
            fetchAssociates();
            fetchManagers();
        }
    }, [userPermissions]);

    // Fetch Coaching Details
    const fetchCoaching = async (userPermissions, tempRole, user, startDate, endDate, agent, supervisor, coachedBy) => {
        try {
            const params = {};
            // admin only | filter results by temp role
            if (userPermissions.has('adminViewCoaching') && tempRole) {
                params.tempRole = tempRole;
            }
            // admin only | filter results by temp user
            if (userPermissions.has('adminViewCoaching') && tempRole) {
                params.tempUser = user;
            }
            // filter results by set date range
            if (startDate && endDate) {
                params.startDate = startDate;
                params.endDate = endDate;
            }
            // filter results by agent
            if (agent) {
                params.agentFilter = agent;
            }
            // filter results by supervisor
            if (supervisor) {
                params.supervisorFilter = supervisor;
            }
            // filter results by coached by
            if (coachedBy) {
                params.coachedByFilter = coachedBy;
            }
            const headers = await getHeaders();
            const { data } = await axios.get(apiPrefix + '/api/coaching-tool', { headers, params });
            return data;
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        }
    };

    // Employee Fetch (Associates = Agents & Leads)
    const fetchAssociates = async () => {
        try {
            const agents = await getApiAgentLookup('Agent')
             // Fetch leads if the user has hrViewCoaching permission.
            if(userPermissions.has('hrViewCoaching')) {
                const leads = await getApiAgentLookup('Lead');
                setAgentList([...agents.data, ...leads.data]);
            } else {
                setAgentList(agents.data);
            }
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        }
    };

    // Employee Fetch (Leads, Supervisors, OM, and Senior OM)
    const fetchManagers = async () => {
        try {
            const leads = await getApiAgentLookup('Lead');
            const supervisors = await getApiAgentLookup('Supervisor');
            const oms = await getApiAgentLookup('Om');
            const seniorOms = await getApiAgentLookup('SeniorOm');
            // Sort by username prior to setting CoachedByList
            const sortedList = [...supervisors.data, ...oms.data, ...seniorOms.data, ...leads.data].sort((a, b) => a.Username.localeCompare(b.Username));

            setCoachedByList(sortedList);
            setSupervisorList(supervisors.data);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        }
    };

    // Update Fetch
    const updateCoaching = async (request) => {
        setLoading(true);
        const headers = await getHeaders();
        try {
            await axios.post(apiPrefix + '/api/coaching-tool', request, { headers });
            Swal.fire({
                title: 'Success!',
                text: 'Thank you for your submission. A copy of your completed actions has been delivered via email.',
                icon: 'success',
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            message.error('There was a problem with your Request');
            console.error(e.message);
        }
    };

    // Fetch Coaching (User Change, Date Filter or Agent/Supervisor/CoachedBy Filter)
    const coachingFetch = async (startDate, endDate, agent, supervisor, coachedBy) => {
        setLoading(true);
        try {
            const results = await fetchCoaching(userPermissions, tempRole, user, startDate, endDate, agent, supervisor, coachedBy);
            setExportCoachings(results.payload);
            // Results Check
            setMaxCount(!!results.maxResults);
            // Track Search
            trackSearch();
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    // Track Search
    const trackSearch = async () => {
        try {
            const headers = await getHeaders();
            const activityTrackingPayload = {
                status: "Search",
            };
            await axios.post(apiPrefix + '/api/coaching-activity', activityTrackingPayload, { headers });
        } catch (e) {
            console.error(e.message);
        }
    }

    // Track Update
    const trackUpdate = async (updateStatus) => {
        try {
            const headers = await getHeaders();
            const activityTrackingPayload = {
                status: updateStatus,
            };
            await axios.post(apiPrefix + '/api/coaching-activity', activityTrackingPayload, { headers });
        } catch (e) {
            console.error(e.message);
        }
    }

    // Table || Expanded Row
    const expandedRowRender = (event) => {
        // show Rework Modal
        const showRework = async () => {
            setIsReworkVisible(true);
        };
        // hide Rework Modal
        const hideRework = async () => {
            setHrNotes('');
            setIsReworkVisible(false);
        };
        // show CS Rework Modal
        const showCsRework = async () => {
            setIsCsReworkVisible(true);
        };
        // hide CS Rework Modal
        const hideCsRework = async () => {
            setNotes('');
            setIsCsReworkVisible(false);
        };

        // HR Rework Fetch
        const reworkFetch = async () => {
            // check if notes are present
            if (!hrFlag || hrNotes.toString('html') === defaultEditorValue) {
                return message.error('Please add Rework notes');
            }
            setIsReworkVisible(false);
            Swal.fire({
                title: 'Are you sure?',
                text: 'Selecting confirm below will require CS Leadership to rework this incident.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    event.dueDate = dueDate;
                    event.hrNotes = hrNotes.toString('html');
                    event.status = 'Rework';
                    const hrReworkTime = moment();
                    event.hrReworkDate = hrReworkTime.toISOString();
                    const pendingTime = event.csReworkDate ? moment(event.csReworkDate) : moment.unix(event.createdTimestamp);
                    event.pendingToReworkTime = event.pendingToReworkTime ? event.pendingToReworkTime + hrReworkTime.diff(pendingTime) : hrReworkTime.diff(pendingTime);
                    const payload = {
                        doc: event,
                        id: event.id,
                    };
                    updateCoaching(payload);
                    setHrNotes('');
                    trackUpdate("Rework");
                }
            });
        };

        // CS Rework Fetch
        const csReworkFetch = async () => {
            // check if notes are present
            if (!notesFlag || notes.toString('html') === defaultEditorValue) {
                return message.error('Please add Rework notes');
            }
            setIsCsReworkVisible(false);
            Swal.fire({
                title: 'Are you sure?',
                text: 'Selecting confirm below will submit your updated details to HR for review.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    event.dueDate = dueDate;
                    event.notes = notes.toString('html');
                    event.status = 'Pending';
                    const csReworkTime = moment();
                    event.csReworkDate = csReworkTime.toISOString();
                    const reworkTime = moment(event.hrReworkDate);
                    event.reworkToPendingTime = event.reworkToPendingTime ? event.reworkToPendingTime + csReworkTime.diff(reworkTime) : csReworkTime.diff(reworkTime);
                    const payload = {
                        doc: event,
                        id: event.id,
                    };
                    updateCoaching(payload);
                    setNotes('');
                    trackUpdate("CS Rework");
                }
            });
        };

        // Approve Fetch
        const approveFetch = () => {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Selecting confirm below will Approve this incident for CS Leadership',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    event.dueDate = dueDate;
                    event.status = 'Ready for Delivery';
                    const approvalTime = moment();
                    event.approvalDate = approvalTime.toISOString();
                    const creationTime = moment.unix(event.createdTimestamp);
                    const pendingTime = event.csReworkDate ? moment(event.csReworkDate) : creationTime;
                    event.pendingToReadyForDeliveryTime = approvalTime.diff(pendingTime);
                    event.creationToReadyForDeliveryTime = approvalTime.diff(creationTime);
                    const payload = {
                        doc: event,
                        id: event.id,
                    };
                    updateCoaching(payload);
                    trackUpdate("Approved");
                }
            });
        };

        const updateCsqaCoachingStatus = async (request) => {
            setLoading(true);
            try {
                const headers = await getHeaders();
                await axios.post(apiPrefix + '/api/csqa', request, { headers });
            } catch (e) {
                message.error('There was a problem with your Request');
                console.error(e.message);
            } finally {
                setLoading(false)
            }
        }

        // Deliver Fetch
        const deliverFetch = () => {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Selecting confirm below will send the Incident Details to the Team Member and Supervisor associated',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    event.dueDate = currentDate;
                    event.status = 'Delivered';
                    const deliverTime = moment();
                    event.deliverDate = deliverTime.toISOString();
                    // records added via csqa forms don't have approvalDate, so will use createdDate
                    const approvalTime = event.approvalDate ? moment(event.approvalDate) : moment(event.createdDate);
                    event.readyForDeliveryToDeliveryTime = Math.abs(deliverTime.diff(approvalTime));
                    const payload = {
                        doc: event,
                        id: event.id,
                    };
                    if (enableCsqaIntegration && event.csqaReviewId) {
                        updateCsqaCoachingStatus(payload);
                    }
                    updateCoaching(payload);
                    trackUpdate("Delivered");
                }
            });
        };

        // Delete Fetch
        const deleteFetch = () => {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Selecting confirm below will Delete your Incident entirely',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    event.status = 'Deleted';
                    event.deleted = true;
                    const payload = {
                        doc: event,
                        id: event.id,
                    };
                    updateCoaching(payload);
                    trackUpdate("Deleted");
                }
            });
        };

        // Download Fetch
        const downloadAttachment = async () => {
            setLoading(true);
            const headers = await getHeaders();
            const key = event.file.key;
            const id = event.id;

            const params = { key, id };
            try {
                await axios.get(apiPrefix + '/api/coaching-attachment', { headers, params })
                    .then((response) => {
                        // Create file link to download
                        let url = response.data;
                        let a = document.createElement('a');
                        a.href = url;
                        a.target = '_blank';
                        a.download = 'download.file';
                        a.click();
                    });
                setLoading(false);
            } catch (e) {
                setLoading(false);
                message.error('There was a problem with your Request');
                console.error(e.message);
            }
        };

        // Return Expanded Row Details
        return (
            <Card
                title='Coaching Details'
                headStyle={{ color: 'rgba(0, 0, 0, 0.45)' }}
                id={`incident${event.formId}`}
            >
                <div className='coaching-row'>
                    {/* Coaching Details */}
                    <div className='coaching-column'>
                        <p>
                            <span className='coaching-label'>
                                Form ID:
                            </span><br />
                            <span className='coaching-value'>
                                {event.formId || 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Agent Email:
                            </span><br />
                            <span className='coaching-value'>
                                {event.agent || 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Supervisor Email:
                            </span><br />
                            <span className='coaching-value'>
                                {event.supervisor || 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Kustomer URL:
                            </span><br />
                            <span className='coaching-value'>
                                {event.kustomerUrl ? <a href={event.kustomerUrl} target='_blank'>{event.kustomerUrl}</a> : 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Date:
                            </span><br />
                            <span className='coaching-value'>
                                {event.date || 'N/A'}
                            </span><br />
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Category:
                            </span><br />
                            <span className='coaching-value'>
                                {event.category || 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Reason:
                            </span><br />
                            <span className='coaching-value'>
                                {event.reason || 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className='coaching-label'>
                                Action:
                            </span><br />
                            <span className='coaching-value'>
                                {event.action || 'N/A'}
                            </span>
                        </p>
                    </div>
                    {/* Coaching Notes & HR Details */}
                    <div className='coaching-column'>
                        <p>
                            <span className='coaching-label'>
                                Notes:
                            </span><br />
                            <span className='coaching-value'>
                                <HtmlRenderer html={event.notes || 'N/A'} />
                            </span>
                        </p>
                        {event.hrbp && userPermissions.has('hrViewCoaching') &&
                            <p>
                                <span className='coaching-label'>
                                    HRBP Associated:
                                </span><br />
                                <span className='coaching-value'>
                                    {event.hrbp}
                                </span>
                            </p>
                        }
                        {event.hrNotes && userPermissions.has('hrViewCoaching') &&
                            <p>
                                <span className='coaching-label'>
                                    HR Notes:
                                </span><br />
                                <span className='coaching-value'>
                                    <HtmlRenderer html={event.hrNotes} />
                                </span>
                            </p>
                        }
                        {event.additionalNotes && userPermissions.has('hrViewCoaching') &&
                            <p>
                                <span className='coaching-label'>
                                    Additional Notes (Non-Agent Facing):
                                </span><br />
                                <span className='coaching-value'>
                                    <HtmlRenderer html={event.additionalNotes} />
                                </span>
                            </p>
                        }
                        {(event.file.key && userPermissions.has('hrViewCoaching') ||
                            event.file.key && event.category === 'Coaching') &&
                            <Button
                                type='primary'
                                shape='round'
                                icon={<DownloadOutlined />}
                                onClick={downloadAttachment}
                                className='coaching-attachment-button'
                            >
                                Download Attachment
                            </Button>
                        }
                    </div>
                    {/* Pending || Actions Available */}
                    {event.status === 'Pending' && userPermissions.has('reworkCoaching') &&
                        <>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<LikeOutlined />}
                                onClick={approveFetch}
                                className='coaching-approve-button'
                            >
                                Approve
                            </Button>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<ReloadOutlined />}
                                onClick={showRework}
                                className='coaching-reprocess-button'
                            >
                                Rework
                            </Button>
                            <Button
                                type='primary'
                                danger
                                shape='round'
                                icon={<DeleteOutlined />}
                                onClick={deleteFetch}
                                className='coaching-delete-button'
                            >
                                Delete
                            </Button>
                            <Modal
                                title='Rework Details'
                                className='coaching-modal'
                                open={isReworkVisible}
                                onOk={reworkFetch}
                                onCancel={hideRework}
                            >
                                <p>Please provide supporting reasoning behind your request for CS Leadership to Rework this incident</p>
                                <TextEditor
                                    value={hrNotes}
                                    onChange={(value) => {
                                        setHrNotes(value);
                                        setHrFlag(true);
                                    }}
                                />
                            </Modal>
                        </>
                    }
                    {/* Rework || Actions Available */}
                    {event.status === 'Rework' && userPermissions.has('editCoaching') &&
                        <>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<ReloadOutlined />}
                                onClick={showCsRework}
                                className='coaching-reprocess-button'
                            >
                                Rework
                            </Button>
                            <Button
                                type='primary'
                                danger
                                shape='round'
                                icon={<DeleteOutlined />}
                                onClick={deleteFetch}
                                className='coaching-delete-button'
                            >
                                Delete
                            </Button>
                            <Modal
                                title='Rework Details'
                                className='coaching-modal'
                                open={isCsReworkVisible}
                                onOk={csReworkFetch}
                                onCancel={hideCsRework}
                            >
                                <p>Please update your note details below to reflect the requested changes by your HRBP</p>
                                <TextEditor
                                    value={notes}
                                    onChange={(value) => {
                                        setNotes(value);
                                        setNotesFlag(true);
                                    }}
                                />
                                <div className='coaching-subheader'>Previous Details:</div>
                                <div className='coaching-preview'>
                                    <HtmlRenderer html={event.notes || 'N/A'} />
                                </div>
                            </Modal>
                        </>
                    }
                    {/* Ready for Delivery || Actions Available */}
                    {event.status === 'Ready for Delivery' && userPermissions.has('deliverCoaching') &&
                        <Button
                            type='primary'
                            shape='round'
                            icon={<LikeOutlined />}
                            onClick={deliverFetch}
                            className='coaching-deliver-button'
                        >
                            Deliver
                        </Button>
                    }
                </div>
            </Card>
        );
    };

    // Define Column Search Properties
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: (props) => (
            <ColumnSearch
                {...{
                    ...props,
                    dataIndex,
                    setSearchText,
                    setSearchedColumn,
                }}
            />
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    // Define Columns
    const columns = [
        {
            title: 'Date',
            className: 'coaching-dashboard-column',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => new Date(a.createdTimestamp).getTime() - new Date(b.createdTimestamp).getTime(),
                multiple: 1,
            },
        },
        {
            title: 'Agent',
            className: 'coaching-dashboard-column-large',
            dataIndex: 'agent',
            ...getColumnSearchProps('agent'),
            sorter: {
                compare: (a, b) => a.agent.localeCompare(b.agent),
                multiple: 2,
            },
        },
        {
            title: 'Supervisor',
            className: 'coaching-dashboard-column-large',
            dataIndex: 'supervisor',
            ...getColumnSearchProps('supervisor'),
            // sorter: true
        },
        {
            title: 'Coached By',
            className: 'coaching-dashboard-column-large',
            dataIndex: 'user',
            ...getColumnSearchProps('user'),
            // sorter: true
        },
        {
            title: 'Category',
            className: 'coaching-dashboard-column',
            dataIndex: 'category',
            onFilter: (value, record) => record.category.indexOf(value) === 0,
            // sorter: true,
        },
        {
            title: 'Reason',
            className: 'coaching-dashboard-column',
            dataIndex: 'reason',
            onFilter: (value, record) => record.reason.indexOf(value) === 0,
            // sorter: true,
        },
        {
            title: 'Action',
            className: 'coaching-dashboard-column',
            dataIndex: 'action',
            onFilter: (value, record) => record.action.indexOf(value) === 0,
            // sorter: true,
        },
        {
            title: 'Status',
            className: 'coaching-dashboard-column',
            dataIndex: 'status',
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status),
                multiple: 2,
            },
            render(value, _record, _index) {
                if (value === 'Rework') {
                    return (
                        <div className='coaching-indicator rework'>
                            < ReloadOutlined /> Rework
                        </div>
                    );
                }
                if (value === 'Pending') {
                    return (
                        <div className='coaching-indicator pending'>
                            <ClockCircleOutlined /> Pending
                        </div>
                    );
                }
                if (value === 'Ready for Delivery') {
                    return (
                        <div className='coaching-indicator ready'>
                            < LikeOutlined /> Ready
                        </div>
                    );
                }
                if (value === 'Delivered') {
                    return (
                        <div className='coaching-indicator delivered'>
                            <CheckOutlined /> Delivered
                        </div>
                    );
                }
                if (value === 'Deleted') {
                    return (
                        <div className='coaching-indicator deleted'>
                            <DeleteOutlined /> Deleted
                        </div>
                    );
                }
            }
        },
        {
            title: 'Form ID',
            className: 'coaching-dashboard-column',
            dataIndex: 'formId',
            ...getColumnSearchProps('formId'),
            // sorter: true,
        },
        {
            title: 'Due Date',
            className: 'coaching-dashboard-column',
            dataIndex: 'dueDate',
            sorter: {
                compare: (a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
                multiple: 1,
            },
            render(value, record, _index) {
                return record.status === 'Delivered' ? 'N/A' : value;
            }
        },
    ];

    // Date Picker (date change, set disabled dates, set date)
    const onDateChange = (value, dateString) => {
        setValue(value);
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };
    const disabledDate = current => {
        if (!date || date.length === 0) {
            return false;
        }
        const tooLate = date[0] && current.diff(date[0], 'days') > 180;
        const tooEarly = date[1] && date[1].diff(current, 'days') > 0;
        return tooEarly || tooLate;
    };
    const onOpenChange = open => {
        if (open) {
            setDate([]);
        }
    };

    // Derived Columns for Column Filters
    const derivedColumn = columns.map((col, index) => {
        if (index === 4) {
            col.filters = (exportCoachings || null).map((row) => ({
                text: row.category,
                value: row.category
            })).filter((item, index, self) => index === self.findIndex((it) => item.text === it.text));
        }
        if (index === 5) {
            col.filters = (exportCoachings || null).map((row) => ({
                text: row.reason,
                value: row.reason
            })).filter((item, index, self) => index === self.findIndex((it) => item.text === it.text));
        }
        if (index === 6) {
            col.filters = (exportCoachings || null).map((row) => ({
                text: row.action,
                value: row.action
            })).filter((item, index, self) => index === self.findIndex((it) => item.text === it.text));
        }
        if (index === 7) {
            col.filters = (exportCoachings || null).map((row) => ({
                text: row.status,
                value: row.status
            })).filter((item, index, self) => index === self.findIndex((it) => item.text === it.text));
        }
        return col;
    });

    // Dropdown Options
    const agentListOptions = agentList?.map((agent) => ({ value: agent.Username, label: agent.Username }));
    const coachedByListOptions = coachedByList?.map((user) => ({ value: user.Username, label: user.Username }));
    const supervisorListOptions = supervisorList?.map((supervisor) => ({ value: supervisor.Username, label: supervisor.Username }));

    // Dropdown Styling
    const dropdownStyle = {
        control: (provided) => ({
            ...provided,
            height: '42.5px',
            minHeight: '42.5px',
            cursor: 'pointer',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 20, // Needed for Table Header
        }),
    };

    // Coaching Dashboard
    return (
        <div>
            <div className='coaching-dashboard-wrapper'>
                <Card title='Filters' bodyStyle={{ padding: '24px 24px 12px' }} style={{ borderTop: 'inherit' }}>
                    <Row type='flex' gutter={8}>
                        <Col>
                            <RangePicker
                                className='coaching-dashboard-date'
                                onChange={onDateChange}
                                value={value || []}
                                disabledDate={disabledDate}
                                onCalendarChange={(value) => { setDate(value); }}
                                onOpenChange={onOpenChange}
                            />
                            <p className='coaching-description muted-text'>
                                Default Range | Last 30 Days <br />
                                {maxCount &&
                                    <span style={{ color: '#FF0000' }}>
                                        Max Results Displayed, please consider reducing your Date Range
                                    </span>
                                }
                            </p>
                        </Col>
                        {userPermissions.has('searchCoaching') &&
                            <>
                                <Col
                                    xs={{ span: 20 }}
                                    sm={{ span: 8 }}
                                    md={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 6 }}
                                    xxl={{ span: 4 }}
                                >
                                    <Select
                                        options={agentListOptions}
                                        onChange={(selectedOption) => {
                                            setAgent(selectedOption.value);
                                            setCoachedBy('');
                                            setSupervisor('');
                                        }}
                                        value={agentListOptions.find((option) => option.value === agent) || ''}
                                        placeholder='Filter by Agent'
                                        styles={dropdownStyle}
                                    />
                                </Col>
                                <Col
                                    xs={{ span: 20 }}
                                    sm={{ span: 8 }}
                                    md={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 6 }}
                                    xxl={{ span: 4 }}
                                >
                                    <Select
                                        options={supervisorListOptions}
                                        onChange={(selectedOption) => {
                                            setAgent('');
                                            setCoachedBy('');
                                            setSupervisor(selectedOption.value);
                                        }}
                                        value={supervisorListOptions.find((option) => option.value === supervisor) || ''}
                                        placeholder='Filter by Supervisor'
                                        styles={dropdownStyle}
                                    />
                                </Col>
                            </>
                        }
                        {userPermissions.has('hrViewCoaching') &&
                            <Col
                                xs={{ span: 20 }}
                                sm={{ span: 8 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}
                                xl={{ span: 6 }}
                                xxl={{ span: 4 }}
                            >
                                <Select
                                    options={coachedByListOptions}
                                    onChange={(selectedOption) => {
                                        setAgent('');
                                        setSupervisor('');
                                        setCoachedBy(selectedOption.value);
                                    }}
                                    value={coachedByListOptions.find((option) => option.value === coachedBy) || ''}
                                    placeholder='Filter by Coached By'
                                    styles={dropdownStyle}
                                />
                            </Col>
                        }
                    </Row>
                </Card>
                <Card bodyStyle={{ padding: '12px 24px 24px' }} style={{ borderTop: 'inherit' }}>
                    <ByTheNumbersComponent
                        exportCoachings={exportCoachings}
                        userPermissions={userPermissions}
                    />
                    <Row justify='end' type='flex'>
                        <Col>
                            <Button
                                danger
                                className='coaching-dashboard-button'
                                disabled=''
                                onClick={() => {
                                    coachingFetch('', '', '', '', '');
                                    setAgent('');
                                    setCoachedBy('');
                                    setSupervisor('');
                                    setValue('');
                                }}
                                style={{ marginRight: '10px' }}
                            >
                                Reset
                            </Button>
                            <Button
                                type='primary'
                                className='coaching-dashboard-button'
                                disabled=''
                                onClick={() => {
                                    coachingFetch(startDate, endDate, agent, supervisor, coachedBy);
                                }}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <div className='coaching-dashboard-table' style={{ marginTop: '20px' }}>
                    <Table
                        columns={derivedColumn}
                        scroll={{ x: 1300 }}
                        rowKey={record => record.formId}
                        loading={loading}
                        expandable={{
                            expandedRowRender: expandedRowRender,
                            rowExpandable: record => record.requestId !== 'Not Expandable',
                            onExpand: record => setExpandedRowIndicator(record.key),
                            expandedRowKeys: expandedRowIndicator

                        }}
                        dataSource={exportCoachings || null}
                    />
                </div>
            </div>
        </div>
    );
}

export default CoachingDashboard;
