import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import './csqa-form.css';
import { getApiAgentLookup } from '../../common/APIUtils';

// Elements
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Row
} from 'antd';
import {
    EditOutlined,
    FormOutlined,
    QuestionCircleOutlined,
    TeamOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import TextEditor from '../../tiptap/TipTap';
import uniqid from 'uniqid';
import validator from 'validator';

// Review Content Library
import reviewLibrary from './review-library';

// Variables
import { useContinuousAuth } from '../../pages/App';
import settings from '../../settings.json';

const currentDate = `${moment().utc().format('YYYY-MM-DD')}`;

// Set API Prefix & OktaAuth
const {
    api_prefix: apiPrefix
} = settings[window.location.host] || settings.default;

// Antd Components
const { Panel } = Collapse;

// Text Editor Config
const defaultEditorValue = '<p><br></p>';

// Review Category Options
const categoryOptions = [
    'Standard Review',
    'Internal Audit',
    '3P Audit',
    'Calibration Benchmark',
    'Calibration Participant'
];

// Same URL validation used for Coaching & CSQA Forms
export const isValidUrl = (kustomerUrl) => {
    try {
        const url = new URL(kustomerUrl);
        const validDomains = [
            'ring.kustomerapp.com', // Kustomer prod
            'zzz-ring-sandbox.kustomerapp.com', // Kustomer sandbox
            'ringcs.lightning.force.com', //SF prod
            'ringcs-dev.sandbox.lightning.force.com', // SF Dev
            'ringcs--uat.sandbox.lightning.force.com', // SF UAT
            'ringcs--qa.sandbox.lightning.force.com', // SF QA
        ];
        const hostname = url.hostname;

        // Check if the URL uses HTTPS and the domain is one of the allowed domains
        if (url.protocol === 'https:' && validDomains.some(domain => hostname === domain || hostname.endsWith('.' + domain))) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

// Styled Components
const EmptyReviewDetails = styled.div`
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    color: #69bce3;
    font-family: equipextralight;
    font-size: 24px;
    margin: 0px 25px 0px 0px;
    min-height: 150px;
    padding-top: 50px;
    text-align: center;

`;
const ErrorMessaging = styled.div`
    color: red;
    font-family: equiplight;
    font-size: 14px;
    padding: 1%;
    text-align: left;
    white-space: pre-line;
`;
const FormBody = styled.div`
    height: 100vh;
    background-color: #f9f9f9;
`;
const FormCheckboxField = styled.div`
    flex: 1 1 auto;
    margin: 5px 0px;
`;
const NotesField = styled.div`
    margin: 25px 25px 25px 0px;
`;
const SidePanelWrapper = styled.div`
    position: sticky;
    top: 10px;
`;
const SidePanelContentBox = styled.div`
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    margin: 0px 0px 0px 25px;
    min-height: 350px;
    padding: 20px;
`;
const PanelText = styled.p`
    
`;
const RseReminder = styled.div`
    margin: 0px 0px 0px 25px;
    text-align: center;
`;

// Dropdown Styling
const dropdownStyle = {
    control: (provided) => ({
        ...provided,
        height: '42.5px',
        minHeight: '42.5px',
        cursor: 'pointer',
        textAlign: 'left'
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 20, // Needed for Table Header
        textAlign: 'left'
    }),
};

//RSE Reminder Content
const rseReminder = (
    <div>
        <p>
            <b>Reminder:</b> <br />Please reference our Article in Central for Examples of Behavior.
        </p>
        <Button
            type='primary'
            href='https://central.ring.com/support_article/rse-audit-how-to-earn-a-yes/'
            target='_blank'
        >
            How to Earn a Yes
        </Button>

    </div>
);

// Employee Fetch (Agents)
const fetchAgents = async (user, role) => {
    const { data } = await getApiAgentLookup('Agent', user, role);
    return data;
};

// Employee Fetch (Leads)
const fetchLeads = async (user, role) => {
    const { data } = await getApiAgentLookup('Lead', user, role);
    return data;
};

function CsqaForm(props) {
    const {
        menuClick,
        user,
        role,
        userPermissions,
        org
    } = props;

    // Hierarchy vars
    const [hierarchy, setHierarchy] = useState([]);
    // Validation vars
    const [loading, setLoading] = useState(true);
    const [requestFailed, setRequestFailed] = useState([]);
    // Form vars
    const [agent, setAgent] = useState('');
    const [category, setCategory] = useState('');
    const [date, setDate] = useState([]);
    const [kustomerUrl, setKustomerUrl] = useState('');
    const [sideContent, setSideContent] = useState('Select a checkbox to see What Earns a Yes');
    const [notes, setNotes] = useState('');
    const [site, setSite] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [review, setReview] = useState('');
    const [checkboxes, setCheckboxes] = useState({});
    const formId = uniqid();

    const { getHeaders } = useContinuousAuth();

    // Update on Review Type Change
    useEffect(() => {
    }, [review]);

    // Fetch Users on Page Load
    useEffect(() => {
        setLoading(true);
        Promise.all([fetchAgents(user, role), fetchLeads(user, role)])
            .then((users) => {
                setHierarchy(users.flat());
            })
            .catch(e => {
                message.error('There was a problem fetching Aspect Hierarchy');
                console.error('=======error', e.message);
            })
            .finally(() => setLoading(false));
    }, [user, role]);

    // Set Team Member Details based on Agent Selection
    const setTmDetails = (selectedOption) => {
        const value = selectedOption.value;
        setAgent(value);
        setSite(hierarchy.find((x) => x.Username === value).Site);
        setSupervisor(hierarchy.find((x) => x.Username === value).Supervisor);
    };

    // Define Categories based on Review Type
    const categories = useMemo(() => {
        const categories = {};
        review && Object.entries(reviewLibrary[review].options).map(([key, value]) => {
            categories[value.category] = { ...categories[value.category], [key]: value };
        });

        return categories;
    }, [review]);

    // Set Message, Label, and True/False associated with Checkbox selection
    const storeCheckboxChange = (key, checked, message) => {
        setSideContent(message);
        setCheckboxes(obj => ({ ...obj, [key]: checked }));
    };

    // Validate Submission
    const getFormErrors = () => {
        const errors = [];
        if (!agent) {
            errors.push('Please Provide Agent Associated');
        }
        if (!supervisor) {
            errors.push(`The Agent you selected: ${agent}, does not have a Supervisor associated. Please reach out to wfm@ring.com to resolve`);
        }
        if (!category) {
            errors.push('Please Provide the Review Category');
        }
        if (!review) {
            errors.push('Please Provide the Review Type');
        }
        if (!validator.isDate(date)) {
            errors.push('Invalid Date');
        }
        if (!kustomerUrl) {
            errors.push('Please Provide the SalesForce URL Associated');
        }
        if (!isValidUrl(kustomerUrl)) {
            errors.push('Invalid Kustomer/SalesForce URL. It must start with "https://" and have a valid kustomer / salesforce domain');
        }
        if (notes === '' || notes.toString('html') === defaultEditorValue) {
            errors.push('Notes Field Cannot be Empty');
        }
        return errors;
    };

    const addContent = () => {
        const payload = {};
        Object.entries(reviewLibrary[review].options).forEach(([key, value]) => {
            payload[key] = {
                checked: !!checkboxes[key],
                label: value.label,
            };
        });

        return payload;
    };

    const createAssociatedCoachingRecord = async (checkboxes, checkboxesKeys, headers) => {
        let additionalNotes = '<strong>Red Flags:</strong><br/>';
        checkboxesKeys.map(key => {
            if (key.includes('redflag') && checkboxes[key].checked) {
                additionalNotes += ` • ${checkboxes[key].label}<br/>`;
            }
        });

        const requestPayload = {
            action: 'No action required',
            agent: agent,
            additionalNotes: additionalNotes,
            category: 'Performance',
            createdDate: currentDate,
            date: date,
            deleted: false,
            dueDate: `${moment().add(5, 'days').format('YYYY-MM-DD')}`,
            file: {},
            formId,
            goodStanding: true,
            hrReview: false,
            kustomerUrl: new URL(kustomerUrl),
            location: site,
            notes: notes.toString('html'),
            reason: 'QA Review',
            status: 'Ready for Delivery',
            supervisor: supervisor,
            csqaReviewId: formId
        };
        await axios.put(apiPrefix + '/api/coaching-tool', requestPayload, { headers });
    };

    // Handle Submission
    const handleSubmit = async (_event) => {
        // Validation
        setRequestFailed([]);
        const formErrors = getFormErrors();
        if (formErrors.length > 0) {
            message.error('Input Validation Error. Please resolve the errors listed below:', 10);
            setRequestFailed(formErrors);
            return;
        }

        setLoading(true);
        const checkboxes = addContent();
        const checkboxesKeys = Object.keys(checkboxes);
        const redflagExists = !!checkboxesKeys.find(key => key.includes('redflag') && checkboxes[key].checked);
        const headers = await getHeaders();
        try {
            // Define Payload
            const requestPayload = {
                agent: agent,
                category: category,
                ...checkboxes,
                date: date,
                deleted: false,
                formId,
                kustomerURL: new URL(kustomerUrl),
                notes: notes.toString('html'),
                review: review,
                submissionDate: currentDate,
                supervisor: supervisor,
                site: site,
                coachingStatus: redflagExists ? 'required' : 'n/a'
            };

            // Deliver request Payload and populate success message
            await axios.put(apiPrefix + '/api/csqa', requestPayload, { headers });

            // If there are redflags, create a associated record in the coaching corner index
            if (org === 'Ring' && redflagExists) {
                await createAssociatedCoachingRecord(checkboxes, checkboxesKeys, headers);
            }

            setRequestFailed('');
            const result = await Swal.fire({
                title: `Form ID: ${formId}`,
                text: 'Success! Thank you for your submission. A copy of your completed coaching has been delivered via email.',
                icon: 'success',
                allowOutsideClick: false,
                allowEscapeKey: false
            });

            if (result.isConfirmed) {
                menuClick('CSQA Dashboard', 'csqa', '');
                // window.location.reload();
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                // Return the specific error message (To account for input validation error message)
                const errorMessage = e.response.data.message;
                message.error(errorMessage);
                console.error('submission error', errorMessage);
                setRequestFailed(errorMessage);
            } else {
                message.error('There was a problem with your Request');
                console.error('submission error', e.message);
                setRequestFailed('There was a problem with your Request');
            }
        } finally {
            setLoading(false);
        }
    };

    // Disable Users from Selecting Dates after Current Date
    const disabledDate = current => {
        return current && current > moment().endOf('day');
    };

    // Dropdown Options
    const hierarchyOptionsList = hierarchy?.map((agent) => ({ value: agent.Username, label: agent.Username }));
    const categoryOptionsList = categoryOptions?.map((category) => ({ value: category, label: category }));
    const typeOptionsList = Object.entries(reviewLibrary).map(([key, value]) => ({ value: key, label: key }));

    // CSQA Dashboard
    return (
        <div className='coaching-corner-form'>
            <FormBody>
                <Form name='csqaForm'>
                    <Row>
                        <Col span={24}>
                            <Divider
                                orientation='left'
                                style={{
                                    color: '#69bce3',
                                    fontSize: '18px',
                                    fontFamily: 'equipmedium',
                                    padding: '0px 10px'
                                }}
                            >
                                <TeamOutlined style={{ marginRight: '5px' }} />
                                Agent Details
                            </Divider>
                        </Col>
                        {/* Agent Selection */}
                        <Col
                            style={{
                                height: '42.5px',
                                margin: '5px 0px',
                                padding: '0px 5px',
                            }}
                            xs={{ span: 20 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 5 }}
                        >
                            <Select
                                options={hierarchyOptionsList}
                                onChange={setTmDetails}
                                required
                                value={hierarchyOptionsList.find((option) => option.value === agent) || ''}
                                placeholder='Select an associate to begin'
                                styles={dropdownStyle}
                            />
                        </Col>
                        {/* Supervisor Selection (auto-populates based on Agent selected) */}
                        <Col
                            style={{
                                height: '42.5px',
                                margin: '5px 0px',
                                padding: '0px 5px',
                            }}
                            xs={{ span: 20 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={[]}
                                isDisabled
                                required
                                value={supervisor || ''}
                                placeholder={supervisor || 'Supervisor Associated'}
                                styles={dropdownStyle}
                            />
                        </Col>
                        {/* Category Selection */}
                        <Col
                            style={{
                                height: '42.5px',
                                margin: '5px 0px',
                                padding: '0px 5px',
                            }}
                            xs={{ span: 20 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={categoryOptionsList}
                                onChange={(selectedOption) => { setCategory(selectedOption.value) }}
                                value={categoryOptionsList.find((option) => option.value === category) || ''}
                                placeholder='Review Category'
                                styles={dropdownStyle}
                            />
                        </Col>
                        {/* Review Type Selection */}
                        <Col
                            style={{
                                height: '42.5px',
                                margin: '5px 0px',
                                padding: '0px 5px',
                            }}
                            xs={{ span: 20 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={typeOptionsList}
                                onChange={(selectedOption) => {
                                    setReview(selectedOption.value);
                                    setCheckboxes({});
                                }}
                                value={typeOptionsList.find((option) => option.value === review) || ''}
                                placeholder='Review Type'
                                styles={dropdownStyle}
                            />
                        </Col>
                        {/* Kustomer URL */}
                        <Col
                            style={{
                                height: '42.5px',
                                margin: '5px 0px',
                                padding: '0px 5px',
                            }}
                            xs={{ span: 20 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 4 }}
                        >
                            <Form.Item
                                name='kustomerUrl'
                                onChange={(value) => setKustomerUrl(value.target.value)}
                                value={kustomerUrl}
                            >
                                <Input
                                    placeholder='SalesForce URL'
                                    style={{
                                        borderRadius: '5px',
                                        fontSize: '18px',
                                        height: '42.5px',
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {/* Date Selection */}
                        <Col
                            style={{
                                height: '42.5px',
                                margin: '5px 0px',
                                padding: '0px 5px',
                            }}
                            xs={{ span: 20 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 3 }}
                        >
                            <DatePicker
                                name='date'
                                disabledDate={disabledDate}
                                onChange={(date, dateString) => setDate(dateString)}
                                size='large'
                                style={{
                                    borderRadius: '5px',
                                    fontSize: '18px',
                                    height: '42.5px',
                                    width: '100%',
                                }}
                                placeholder='Select Date'
                            />
                        </Col>
                        {/* Review Details (Boolean Fields & Notes) */}
                        <Col
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                borderRight: '3px solid #fff',
                                margin: '20px 0px'
                            }}
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: 18, order: 1 }}
                            xl={{ span: 18, order: 1 }}
                        >
                            <Col span={24}>
                                <Divider
                                    orientation='left'
                                    style={{
                                        color: '#69bce3',
                                        fontSize: '18px',
                                        fontFamily: 'equipmedium',
                                        paddingRight: '25px'
                                    }}
                                >
                                    <FormOutlined style={{ marginRight: '5px' }} />
                                    Review Details
                                </Divider>
                                {
                                    review ?
                                        <Collapse
                                            accordion
                                            defaultActiveKey={['0']}
                                            style={{
                                                margin: '25px 25px 25px 0px',
                                            }}
                                        >
                                            {Object.entries(categories).map(([title, options], index) => (

                                                <Panel
                                                    header={title}
                                                    key={index}
                                                >
                                                    <PanelText>
                                                        Please select all that apply below, to see "what earns a yes" select the point associated:
                                                    </PanelText>
                                                    {
                                                        Object.entries(options).map(([key, option]) => (
                                                            <FormCheckboxField>
                                                                <Checkbox
                                                                    key={key}
                                                                    checked={checkboxes[key]}
                                                                    onChange={(event) => {
                                                                        storeCheckboxChange(key, event.target.checked, option.message);
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </Checkbox>
                                                            </FormCheckboxField>
                                                        ))
                                                    }
                                                </Panel>
                                            ))}
                                        </Collapse>
                                        :
                                        <EmptyReviewDetails>
                                            Select a Review Type to Get Started!
                                        </EmptyReviewDetails>
                                }
                                {/* Notes Field */}
                                <Divider
                                    orientation='left'
                                    style={{
                                        color: '#69bce3',
                                        fontSize: '18px',
                                        fontFamily: 'equipmedium',
                                        paddingRight: '25px'
                                    }}
                                >
                                    <EditOutlined style={{ marginRight: '5px' }} />
                                    Notes / Comments
                                </Divider>
                                <NotesField>
                                    <TextEditor
                                        value={notes}
                                        onChange={(value) => {
                                            setNotes(value)
                                        }}
                                    />
                                </NotesField>
                            </Col>
                            <Col>
                            </Col>
                        </Col>
                        {/* Sidebar Content (What Earns a Yes) */}
                        <Col
                            style={{
                                margin: '20px 0px'
                            }}
                            xs={{ span: 24, order: 2 }}
                            sm={{ span: 24, order: 2 }}
                            md={{ span: 24, order: 2 }}
                            lg={{ span: 6, order: 2 }}
                            xl={{ span: 6, order: 2 }}
                        >
                            <SidePanelWrapper>
                                <Divider
                                    style={{
                                        color: '#69bce3',
                                        fontSize: '18px',
                                        fontFamily: 'equipmedium',
                                        padding: '0px 0px 0px 25px'
                                    }}
                                >
                                    What Earns a Yes
                                    <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                                </Divider>
                                <SidePanelContentBox>
                                    {sideContent}
                                </SidePanelContentBox>
                                {review === "RSE Consultation v2" &&
                                    <RseReminder>
                                        <Alert
                                            message={rseReminder}
                                            type='info'
                                            style={{
                                                margin: '15px 0px'
                                            }}
                                        />
                                    </RseReminder>
                                }
                                <Col
                                    style={{
                                        margin: 'auto'
                                    }}
                                    xs={{ span: 20 }}
                                    sm={{ span: 20 }}
                                    md={{ span: 20 }}
                                    lg={{ span: 20 }}
                                    xl={{ span: 12 }}
                                    xxl={{ span: 12 }}
                                >
                                    <Button
                                        disabled={loading}
                                        type='submit'
                                        onClick={handleSubmit}
                                        style={{
                                            backgroundColor: '#1998d5',
                                            borderRadius: '25px',
                                            color: '#fff',
                                            display: 'block',
                                            fontFamily: 'equipmedium',
                                            fontSize: '16px',
                                            height: '48px',
                                            marginTop: '100px',
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        {loading ? 'Loading...' : 'Submit'}
                                    </Button>
                                    {requestFailed.length > 0 && requestFailed.map((error, index) =>
                                        <ErrorMessaging key={index}>- {error}</ErrorMessaging>
                                    )}
                                </Col>
                            </SidePanelWrapper>
                        </Col>
                    </Row>
                </Form>
            </FormBody>
        </div>
    );
}

export default CsqaForm;
