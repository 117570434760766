import React, { useState, useEffect } from 'react';
import axios from 'axios';

//Elements
import { Alert, Button, Col, DatePicker, Form, Input, message, Popover, Tooltip, Row } from 'antd';
import { AmazonOutlined } from '@ant-design/icons';
import moment from 'moment';
import Select from 'react-select';
import content from './coaching-catalog';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import validator from 'validator';
import TextEditor from '../../tiptap/TipTap';

//CSS
import './coaching-corner.css';

//Internal
import { getApiAgentLookup } from '../../common/APIUtils';
import { isValidUrl } from '../../components/csqa-platform/CsqaForm';
import { useContinuousAuth } from '../../pages/App';
import settings from '../../settings.json';

//Define Prefix & OktaAuth
const settingEnv = settings[window.location.host] || settings.default;
const {
    api_prefix: apiPrefix,
} = settingEnv;

//Coaching Actions
const coachingActions = [
    'Counsel',
    'First Written',
    'Second Written',
    'No action required',
];

const hrActions = [
    'Final Written',
    'Separation',
];

//Additional Notes Tooltip Content
const additionalNotesWarning = () => (
    <div className='additionalNotesPopover'>
        <p>
            When entering notes from your coaching with an associate, users are expected to follow the Do’s and Don’ts Best Practices.<br /><br />
            Please review these guidelines before recording documentation in Coaching Corner.<br />
        </p>
        <Button
            type='primary'
            danger
            href='https://ring.shelf.io/read/00146c14-a240-478a-aa5c-c72661679bf9'
            target='_blank'
            className='additionalNotesPopoverButton'
        >
            Best Practices
        </Button>

    </div>
);

//HR Reminder Content
const hrReminder = (
    <div>
        <p>
            <b>Reminder:</b> <br />Please use the corrective action template for formal coaching documentation.
        </p>
        <Button
            type='primary'
            href='https://central.ring.com/support_article/ring-community-support-l1-to-l3-performance-management/'
            target='_blank'
        >
            Corrective Action Template
        </Button>

    </div>
);

//Attachment Reminder Content
const attachmentReminder = (
    <div>
        <p style={{ marginBottom: '5px' }}>
            <b>Reminder:</b> <br />
            Any attachments uploaded in this section will be viewable by the coached employee.
        </p>
    </div>
);


//Define Constants
const currentDate = `${moment().utc().format('YYYY-MM-DD')}`;
const dueDate = `${moment().add(5, 'days').format('YYYY-MM-DD')}`;
const defaultEditorValue = '<p><br></p>';

// Employee Fetch (Agents)
const fetchAgents = async (user, role) => {
    const { data } = await getApiAgentLookup('Agent', user, role)
    return data;
}

// Employee Fetch (Leads)
const fetchLeads = async (user, role) => {
    const { data } = await getApiAgentLookup('Lead', user, role)
    return data;
}


function CoachingForm(props) {
    const {
        menuClick,
        user,
        role,
        userPermissions
    } = props;

    const [action, setAction] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [agent, setAgent] = useState('');
    const [attachmentBody, setAttachmentBody] = useState(undefined);
    const [category, setCategory] = useState('');
    const [date, setDate] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [goodStanding, setGoodStanding] = useState(true);
    const [hierarchy, setHierarchy] = useState([]);
    const [hrOptions, setHrOptions] = useState('');
    const [hrReview, setHrReview] = useState(false);
    const [kustomerUrl, setKustomerUrl] = useState('');
    const [location, setLocation] = useState('');
    const [notes, setNotes] = useState('');
    const [reason, setReason] = useState('');
    const [coachingStatus, setStatus] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [loading, setLoading] = useState(false);
    const [requestFailed, setRequestFailed] = useState('');

    const { getHeaders } = useContinuousAuth();

    // Fetch Users on Page Load
    useEffect(() => {
        setLoading(true);
        Promise.all([fetchAgents(user, role), fetchLeads(user, role)])
            .then((users) => {
                setHierarchy(users.flat());
            })
            .catch(e => {
                message.error('There was a problem with your Request');
                console.error(e.message);
            })
            .finally(() => setLoading(false));
    }, [user, role]);


    // Set Team Member Details based on Agent Selection
    const setTmDetails = (selectedOption) => {
        const value = selectedOption.value;
        setAgent(value);
        setLocation(hierarchy.find((x) => x.Username === value).Site);
        setSupervisor(hierarchy.find((x) => x.Username === value).Supervisor);
    };

    // Set HR Status based on Action Selection
    const setActionDetails = (selectedOption) => {
        const value = selectedOption.value;
        setAction(value);
        if (coachingActions.includes(value)) {
            setGoodStanding(true);
            setHrReview(false);
            setStatus('Delivered');
        }
        if (hrActions.includes(value)) {
            setGoodStanding(false);
            setHrReview(true);
            setStatus('Pending');
        }
    };

    // Save Attachment to S3
    const attachmentFetch = async (file) => {
        setLoading(true);
        const headers = await getHeaders();
        const payload = {
            uid: uniqid(),
            type: file.type,
        }
        try {
            // Deliver request Payload and populate success message
            const response = await axios.post(apiPrefix + '/api/coaching-attachment', payload, { headers });
            setRequestFailed('');

            // send file
            const fields = response?.data.presignedPost.fields;
            const url = response?.data.presignedPost.url;

            let formData = new FormData();
            Object.entries(fields).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('file', file);
            await axios.post(url, formData);

            return {
                key: response?.data.key
            };
        } catch (e) {
            setRequestFailed('There was a problem with your Upload. File Type is not Supported');
            setDisabled(false);
            message.error('There was a problem with your Upload. File Type is not Supported');
            throw e;
        } finally {
            setLoading(false);
        }
    };

    // Validate Submission
    const isValid = () => {
        setRequestFailed('');
        if (!agent) {
            setRequestFailed('Please Provide Agent Associated');
            setDisabled(false);
            return false
        }
        if (!category) {
            setRequestFailed('Please Provide Category Associated');
            setDisabled(false);
            return false
        }
        if (!reason) {
            setRequestFailed('Please Provide Reason Associated');
            setDisabled(false);
            return false
        }
        if (!action) {
            setRequestFailed('Please Provide Action Associated');
            setDisabled(false);
            return false
        }
        if (!validator.isDate(date)) {
            setRequestFailed('Invalid Date');
            setDisabled(false);
            return false
        }
        if (kustomerUrl && !isValidUrl(kustomerUrl)) {
            setRequestFailed('Invalid Kustomer/SalesForce URL. It must start with "https://" and have a valid kustomer / salesforce domain');
            setDisabled(false);
            return false;
        }
        if (notes === '' || notes.toString('html') === defaultEditorValue) {
            setRequestFailed('Notes Field Cannot be Empty');
            setDisabled(false);
            return false;
        }
        if (!validator.isEmail(supervisor)) {
            setSupervisor('unassignedSupervisor@ring.com');
        }
        return true
    };

    // Handle Submission
    const handleSubmit = async (_event) => {
        setLoading(true);
        const headers = await getHeaders();
        try {
            // Attachment Check
            let attachment = {};
            if (attachmentBody) {
                attachment = await attachmentFetch(attachmentBody);
            }
            // Define Payload
            const requestPayload = {
                action: action,
                agent: agent,
                additionalNotes: additionalNotes.toString('html') || defaultEditorValue,
                category: category,
                createdDate: currentDate,
                date: date,
                deleted: false,
                dueDate: dueDate,
                file: attachment,
                formId: uniqid(),
                goodStanding: goodStanding,
                hrReview: hrReview,
                kustomerUrl: kustomerUrl ? new URL(kustomerUrl) : 'N/A',
                location: location,
                notes: notes.toString('html'),
                reason: reason,
                status: coachingStatus,
                supervisor: supervisor,
            }
            // Deliver request Payload and populate success message
            await axios.put(apiPrefix + '/api/coaching-tool', requestPayload, { headers });
            setRequestFailed('');
            // Track Create
            trackCreate();
            const result = await Swal.fire({
                title: 'Success!',
                text: 'Thank you for your submission. A copy of your completed coaching has been delivered via email.',
                icon: 'success',
            });
            if (result.isConfirmed) {
                menuClick('Coaching Dashboard', 'coaching', '');
                // window.location.reload();
            }
        } catch (e) {
            setDisabled(false);
            if (e.response && e.response.data && e.response.data.message) {
                // Return the specific error message (To account for input validation error message)
                const errorMessage = e.response.data.message;
                message.error(errorMessage);
                console.error('submission error', errorMessage);
                setRequestFailed(errorMessage);
            } else {
                message.error('There was a problem with your Request');
                console.error('submission error', e.message);
                setRequestFailed('There was a problem with your Request');
            }
        } finally {
            setLoading(false);
        }
    };

    const submissionConfirmation = async (event) => {
        //confirmation text
        let text = 'Once you click “submit for delivery,” the formal coaching will be delivered via email to the employee and published to the Coaching Corner dashboard.';
        if (coachingStatus === 'Pending') {
            text = 'Once you click “submit for delivery,” the formal coaching will be delivered via email to the HR Team and published to the Coaching Corner dashboard.';
        }
        setDisabled(true);
        // Validation
        if (isValid()) {
            //confirmation popup
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit for Delivery'
            });
            if (result.isConfirmed) {
                await handleSubmit(event)
            } else {
                setDisabled(false);
            }
        } else {
            message.error('Required Field Missing');
        }
    };

    // Track Create
    const trackCreate = async () => {
        try {
            const headers = await getHeaders();
            const activityTrackingPayload = {
                status: "Create",
            };
            await axios.post(apiPrefix + '/api/coaching-activity', activityTrackingPayload, { headers });
        } catch (e) {
            console.error(e.message);
        }
    }

    // Disable Users from Selecting Dates after Current Date
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    // Dropdown Options
    const hierarchyOptions = hierarchy?.map((agent) => ({ value: agent.Username, label: agent.Username }));
    const categoryOptions = (role === 'Lead' ? ['Coaching'] : content.map(c => c.Category))
        .map(category => ({ value: category, label: category }));
    const reasonOptions = category ? content.find((rev) => rev.Category === category)['reasons']
        .map((category) => ({ value: category.value, label: category.value }))
        : [];
    const actionOptions = hrOptions?.action === true ? [
        {
            label: 'No HR involvement',
            options: [
                { value: 'Counsel', label: 'Counsel' },
                { value: 'First Written', label: 'First Written' },
                { value: 'Second Written', label: 'Second Written' },
                { value: 'No action required', label: 'No action required' },
            ]
        },
        {
            label: 'HR will be included',
            options: [
                { value: 'Final Written', label: 'Final Written' },
                { value: 'Separation', label: 'Separation' }
            ]
        }
    ] : [
        { value: 'No action required', label: 'No action required' }
    ];

    // Dropdown Styling
    const dropdownStyle = {
        control: (provided) => ({
            ...provided,
            height: '42.5px',
            minHeight: '42.5px',
            cursor: 'pointer',
            textAlign: 'left'
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 20, // Needed for Table Header
            textAlign: 'left'
        }),
    };
    // Dropdown Group Styling
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    // Group Label for Dropdown
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
        </div>
    );


    // Coaching Form
    return (
        <div className='coaching-corner-form'>
            <Form name='coaching_form'>
                <Row>
                    <Col
                        className='form-column'
                        xs={{ span: 24, order: 1 }}
                        sm={{ span: 24, order: 1 }}
                        md={{ span: 24, order: 1 }}
                        lg={{ span: 18, order: 1 }}
                        xl={{ span: 18, order: 1 }}
                    >
                        <Col span={24} className='coaching-corner-subtitle'>User Details</Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 10 }}
                            md={{ span: 10 }}
                            lg={{ span: 10 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 6 }}
                            coachingfield='agent'
                            className='coaching-field'
                        >
                            <Select
                                options={hierarchyOptions}
                                onChange={setTmDetails}
                                required
                                value={hierarchyOptions.find((option) => option.value === agent) || ''}
                                placeholder='Select an associate to begin'
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 10 }}
                            md={{ span: 10 }}
                            lg={{ span: 10 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 6 }}
                            coachingfield='supervisor'
                            className='coaching-field'
                        >
                            <Select
                                options={[]}
                                isDisabled
                                required
                                value={supervisor || ''}
                                placeholder={supervisor || 'Supervisor Associated'}
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 10 }}
                            md={{ span: 10 }}
                            lg={{ span: 10 }}
                            xl={{ span: 7 }}
                            xxl={{ span: 6 }}
                            coachingfield='kustomerUrl'
                            className='coaching-field'
                        >
                            <Form.Item
                                name='kustomerURL'
                                onChange={(value) => setKustomerUrl(value.target.value)}
                                value={kustomerUrl}
                            >
                                <Tooltip color='blue' title='Leave field Blank if N/A'>
                                    <Input placeholder='SalesForce URL Associated' className='coaching-input' />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 10 }}
                            md={{ span: 10 }}
                            lg={{ span: 10 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 5 }}
                            coachingfield='date'
                            className='coaching-field'
                        >
                            <DatePicker
                                name='date'
                                disabledDate={disabledDate}
                                onChange={(date, dateString) => setDate(dateString)}
                                className='coaching-input coaching-date'
                            />
                        </Col>
                        <Col span={24} className='coaching-corner-subtitle'>Notes</Col>
                        <Col span={24} coachingfield='notes' className='coaching-notes'>
                            <TextEditor
                                value={notes}
                                onChange={(value) => {
                                    setNotes(value)
                                }}
                            />
                        </Col>
                        <Col span={24} className='coaching-corner-subtitle notes-spacer'>
                            Notes for Supervisors, Operational Managers, and HR <span className='coaching-disclaimer'> Optional </span>
                        </Col>
                        <Popover
                            title='Reminder'
                            trigger="click"
                            content={additionalNotesWarning}
                            overlayClassName='additionalNotesWrapper'
                        >
                            <Col span={24} coachingfield='additionalNotes' className='coaching-notes'>
                                <TextEditor
                                    value={additionalNotes}
                                    onChange={(value) => {
                                        setAdditionalNotes(value)
                                    }}
                                />
                            </Col>
                        </Popover>
                    </Col>
                    <Col
                        xs={{ span: 24, order: 2 }}
                        sm={{ span: 24, order: 2 }}
                        md={{ span: 24, order: 2 }}
                        lg={{ span: 6, order: 2 }}
                        xl={{ span: 6, order: 2 }}
                        className='coaching-details'
                    >
                        <Col span={24} className='coaching-corner-subtitle'>Coaching Details</Col>
                        <Col span={22} coachingfield='category' className='coaching-details-option'>
                            <Select
                                options={categoryOptions}
                                onChange={(selectedOption) => {
                                    setCategory(selectedOption.value);
                                    setReason('');
                                    setAction('');
                                }}
                                value={categoryOptions.find((option) => option.value === category) || ''}
                                placeholder='Select Category'
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col span={22} coachingfield='reason' className='coaching-details-option'>
                            <Select
                                options={reasonOptions}
                                onChange={(selectedOption) => {
                                    setReason(selectedOption.value);
                                    setAction('');
                                    setHrOptions(content.find((rev) => rev.Category === category)['reasons'].find((action => action.value === selectedOption.value)));
                                }}
                                value={reasonOptions.find((option) => option.value === reason) || ''}
                                placeholder='Select Reason'
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col as={Col} span={22} coachingfield='action' className='coaching-details-option'>
                            <Select
                                options={actionOptions}
                                onChange={setActionDetails}
                                placeholder='Select Action'
                                formatGroupLabel={formatGroupLabel}
                                styles={dropdownStyle}
                            />
                            {hrOptions?.action === true && hrReview ?
                                <div className='coaching-corner-warning'>
                                    <AmazonOutlined /> HR will be included on submission
                                    <Alert
                                        message={hrReminder}
                                        type='info'
                                        style={{
                                            margin: '15px 0px'
                                        }}
                                    />
                                </div> : <div />
                            }
                        </Col>
                        <Col span={22} coachingfield='attachment' className='coaching-details-option'>
                            <div className='coaching-attachment'>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept=".jpg, .jpeg, .png, .csv, .doc, .docx, .xls, .xlsx, .pdf"
                                    onChange={(e) => setAttachmentBody(e.target.files[0])}
                                />
                                <p className='coaching-disclaimer'>
                                    Max File Size: 5MB
                                    <span>Supported File Types: jpg, png, csv, doc, docx, xls, xlsx, pdf</span>
                                </p>
                            </div>
                            {category === 'Coaching' &&
                                <Alert
                                    message={attachmentReminder}
                                    type='info'
                                    style={{
                                        margin: '15px 0px'
                                    }}
                                />
                            }
                        </Col>

                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 20 }}
                            md={{ span: 20 }}
                            lg={{ span: 20 }}
                            xl={{ span: 12 }}
                            xxl={{ span: 12 }}
                            coachingfield='submit'
                            className='coaching-details-option coaching-form-submit'
                        >
                            <Button
                                disabled={disabled}
                                className='coaching-corner-button'
                                type='submit'
                                onClick={submissionConfirmation}
                            >
                                {loading ? 'Loading...' : 'Submit'}
                            </Button>
                            <div className='coaching-corner-error'>{requestFailed}</div>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default CoachingForm;
