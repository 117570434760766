import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { EmployeePayload, getApiAgentLookup } from '../../common/APIUtils';

//Elements
import ringIconUrl from 'url:../../images/ring_logo_white.png';
import {
    Col,
    Menu,
    message,
    Row
} from 'antd';
import type { MenuProps } from 'antd';
import {
    BarChartOutlined,
    FormOutlined,
    PieChartOutlined
} from '@ant-design/icons';
import Select, { StylesConfig, CSSObjectWithLabel } from 'react-select';

//CSS
import '../../components/coaching-corner/coaching-corner.css';

//styled components
const Header = styled.div`
    background-color: #A4B3C3;
    position: relative;
    height: 105px;

    @media (max-width: 700px) {
        display: flex;
        flex-content: column;
        height: fit-content;
    }
`;
const LogoWrapper = styled.div`
    cursor: pointer;
`;
const RingIcon = styled.div`
    width: 90px;
    height: 80px;
    top: 10px;
    left: 20px;
    float: left;
`;
const LogoImg = styled.img`
    align-self: center;
    margin: 0px;
    width: -webkit-fill-available;
`;
const LogoDivider = styled.div`
    float: left;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #fff;
    height: 55px;
    width: 1px;
`;
const LogoHeaderText = styled.p`
    font: 26px equipextralight;
    color: #fff;
    float: left;
    margin-top: -5px;
    margin-bottom: 0px;
`;
const LogoSubHeaderText = styled.span`
    font: 18px equiplight;
    color: #fff;
    float: left;
`;

// Only 1P users should have access to Create Coaching
const createCoachingAccess = (org: string, userPermissions: Set<string>): boolean =>
    userPermissions.has('createCoaching') && (org === 'Ring' || org === 'Amazon');

// Only 1P users should have access to View Coaching
const viewCoachingAccess = (org: string, userPermissions: Set<string>): boolean =>
    userPermissions.has('viewCoaching') && (org === 'Ring' || org === 'Amazon');

type Props = PropsWithChildren<{
    userPermissions: Set<string>;
    menuClick: (menuTitle: string, createKey: string, dashboardKey: string) => void;
    tempRoleClick: (value: any) => void;
    tempUserClick: (value: any) => void;
    current: string;
    createKey: string;
    dashboardKey: string;
    user: string;
    org: string;
    tempRole: string;
}>;

function PageHeader(props: Props) {
    const {
        userPermissions,
        menuClick,
        tempRoleClick,
        tempUserClick,
        current,
        createKey,
        dashboardKey,
        user,
        org,
        tempRole

    } = props;

    const [agentList, setAgentList] = useState<EmployeePayload>([]);
    const [leadList, setLeadList] = useState<EmployeePayload>([]);
    const [supervisorList, setSupervisorList] = useState<EmployeePayload>([]);

    // Fetch Employees (Admin mock user testing only)
    useEffect(() => {
        if (userPermissions.has('adminViewCoaching')) {
            fetchAgents();
            fetchLeads();
            fetchSupervisors();
        }
    }, [userPermissions]);

    // Watch for Current Page
    useEffect(() => {
    }, [current]);

    // Watch for userPermissions & org
    useEffect(() => {
    }, [userPermissions, org]);


    // Employee Fetch (Agents)
    const fetchAgents = async () => {
        try {
            const agents = await getApiAgentLookup('Agent')
            setAgentList(agents.data);
        } catch (e: any) {
            message.error('There was a problem with your Request');
            console.error('Error:', e);
        }
    }
    // Employee Fetch (Leads)
    const fetchLeads = async () => {
        try {
            const leads = await getApiAgentLookup('Lead')
            setLeadList(leads.data);
        } catch (e: any) {
            message.error('There was a problem with your Request');
            console.error('Error:', e);
        }
    }
    // Employee Fetch (Supervisors)
    const fetchSupervisors = async () => {
        try {
            const supervisors = await getApiAgentLookup('Supervisor');
            setSupervisorList(supervisors.data);
        } catch (e: any) {
            message.error('There was a problem with your Request');
            console.error('Error:', e);
        }
    }

    //Temporary Role Dropdown Options
    const tempRoleOptions = [
        { value: 'Agent', label: 'Agent' },
        { value: 'Lead', label: 'Lead' },
        { value: 'Supervisor', label: 'Supervisor' },
        { value: 'Admin', label: 'HR' },
    ];

    // Admin Only - Testing Dropdown Options
    const agentListOptions = agentList?.map((agent) => ({ value: agent.Username, label: agent.Username }));
    const leadListOptions = leadList?.map((lead) => ({ value: lead.Username, label: lead.Username }));
    const supervisorListOptions = supervisorList?.map((supervisor) => ({ value: supervisor.Username, label: supervisor.Username }));

    // Dropdown Styling
    const dropdownStyle: StylesConfig = {
        control: (provided: CSSObjectWithLabel) => ({
            ...provided,
            margin: '2px 0px',
            height: '42.5px',
            minHeight: '42.5px',
            cursor: 'pointer',
            textAlign: 'left'
        }),
        menu: (provided: CSSObjectWithLabel) => ({
            ...provided,
            zIndex: 20, // Needed for Table Header
            textAlign: 'left'
        }),
    };

    // Menu Items (dependent on user permissions)
    const createMenuItems: MenuProps['items'] = [
        createCoachingAccess(org, userPermissions) && {
            key: 'createCoaching',
            label: 'Create Coaching',
            icon: <FormOutlined />,
            onClick: () => menuClick('Create Coaching', 'createCoaching', '')
        },
        userPermissions.has('createCsqa') && {
            key: 'createCsqa',
            label: 'Create CSQA',
            icon: <FormOutlined />,
            onClick: () => menuClick('Create CSQA Review', 'createCsqa', '')
        },
    ].filter(Boolean) as MenuProps['items'];;

    const dashboardMenuItems: MenuProps['items'] = [
        viewCoachingAccess(org, userPermissions) && {
            key: 'coaching',
            label: 'Coaching Dashboard',
            icon: <BarChartOutlined />,
            onClick: () => menuClick('Coaching Dashboard', '', 'coaching')
        },
        userPermissions.has('viewCsqa') && {
            key: 'csqa',
            label: 'CSQA Dashboard',
            icon: <BarChartOutlined />,
            onClick: () => menuClick('CSQA Dashboard', '', 'csqa')
        },
        userPermissions.has('adminViewCoaching') && {
            key: 'activity',
            label: 'Activity Tracker',
            icon: <PieChartOutlined />,
            onClick: () => menuClick('Activity Tracker', '', 'activity')
        },
    ].filter(Boolean) as MenuProps['items'];;

    return (
        < Header >
            <Row justify='end'>
                {/* BRANDING */}
                <Col
                    flex='auto'
                    style={{
                        paddingTop: '25px',
                        paddingLeft: '25px'
                    }}
                >
                    <LogoWrapper>
                        <RingIcon>
                            <LogoImg
                                src={ringIconUrl}
                                title='iconheader'
                            />
                        </RingIcon>
                        <LogoDivider />
                        <LogoHeaderText>Engagement Suite<br />
                            <LogoSubHeaderText>{current}</LogoSubHeaderText>
                        </LogoHeaderText>
                    </LogoWrapper>
                </Col>
                {/* TESTING ONLY - AVAILABLE TO ADMIN USERS */}
                <Col
                    flex="240px"
                    className='coaching-role-dropdown'
                >
                    {userPermissions.has('adminViewCoaching') &&
                        <Select
                            options={tempRoleOptions}
                            onChange={tempRoleClick}
                            value={tempRoleOptions.find((option) => option.value === tempRole)}
                            placeholder='Select Role'
                            styles={dropdownStyle}
                        />
                    }
                    {tempRole === 'Agent' &&
                        <Select
                            options={agentListOptions}
                            onChange={tempUserClick}
                            value={agentListOptions.find((option) => option.value === user)}
                            placeholder='Select Agent'
                            styles={dropdownStyle}
                        />
                    }
                    {tempRole === 'Lead' &&
                        <Select
                            options={leadListOptions}
                            onChange={tempUserClick}
                            value={leadListOptions.find((option) => option.value === user)}
                            placeholder='Select Lead'
                            styles={dropdownStyle}
                        />
                    }
                    {tempRole === 'Supervisor' &&
                        <Select
                            options={supervisorListOptions}
                            onChange={tempUserClick}
                            value={supervisorListOptions.find((option) => option.value === user)}
                            placeholder='Select Supervisor'
                            styles={dropdownStyle}
                        />
                    }
                </Col>
                {/* MENU */}
                <Col
                    flex="300px"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                    }}
                >
                    {(userPermissions.has('createCoaching') || userPermissions.has('createCsqa')) &&
                        <Menu
                            mode="horizontal"
                            className='coaching-corner-option'
                            selectedKeys={[createKey]}
                            style={{
                                color: '#fff',
                                fontFamily: 'equiplight',
                            }}
                            items={[
                                {
                                    key: 'CreateSubMenu',
                                    label: 'Create',
                                    icon: <FormOutlined />,
                                    children: createMenuItems,
                                    popupClassName: 'coaching-options-submenu'
                                },
                            ]}
                        />
                    }
                    <Menu
                        mode="horizontal"
                        className='coaching-corner-option'
                        selectedKeys={[dashboardKey]}
                        style={{
                            color: '#fff',
                            fontFamily: 'equiplight',
                        }}
                        items={[
                            {
                                key: 'DashboardSubMenu',
                                label: 'Dashboards',
                                icon: <BarChartOutlined />,
                                children: dashboardMenuItems,
                                popupClassName: 'coaching-options-submenu'
                            },
                        ]}
                    />
                </Col>
            </Row >
        </Header >
    );
}

export default PageHeader;