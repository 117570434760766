{
  "default": {
    "okta": {
      "cid": "0oas28pso8zic51W51t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://engagement-suite.cs-tools.dev.ring.com",
    "cstools": {
      "enableBanner": true,
      "enableCsqaIntegration": true
    },
    "stage": "beta"
  },
  "engagement-suite.cs-tools.dev.ring.com": {
    "okta": {
      "cid": "0oas28pso8zic51W51t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://engagement-suite.cs-tools.dev.ring.com",
    "cstools": {
      "enableBanner": true,
      "enableCsqaIntegration": true
    },
    "stage": "beta"
  },
  "engagement-suite.cs-tools.qa.ring.com": {
    "okta": {
      "cid": "0oas28pso8zic51W51t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://engagement-suite.cs-tools.qa.ring.com",
    "cstools": {
      "enableBanner": true,
      "enableCsqaIntegration": true
    },
    "stage": "gamma"
  },
  "engagement-suite.cs-tools.ring.com": {
    "okta": {
      "cid": "0oas28y2n517SWQP71t7",
      "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
    },
    "api_prefix": "https://engagement-suite.cs-tools.ring.com",
    "cstools": {
      "enableBanner": false,
      "enableCsqaIntegration": true
    },
    "stage": "prod"
  }
}
