import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

//Elements
import {
    Button,
    Input,
    Card,
    Row,
    Col,
    Space,
    DatePicker,
    Table,
    message,
    Modal,
    Statistic,
    Tabs,
    Divider
} from 'antd';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Select from 'react-select';

//CSS
import './coaching-corner.css';

//Variables
import { useContinuousAuth } from '../../pages/App';
import settings from '../../settings.json';

// Set API Prefix & OktaAuth
const {
    api_prefix: apiPrefix,
} = settings[window.location.host] || settings.default;


// Date Picker (set current & due date)
const { RangePicker } = DatePicker;

// Table Column Search/Sort Functionality
function ColumnSearch(props) {
    const {
        dataIndex,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        visible,
        setSearchText,
        setSearchedColumn,
    } = props;

    const inputRef = useRef();

    useEffect(() => {
        if (visible && inputRef.current) {
            setTimeout(() => inputRef.current.select(), 100);
        }
    }, [visible, inputRef])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    return (
        <div style={{ padding: 8 }}>
            <Input
                ref={inputRef}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    danger
                    className='coaching-dashboard-button'
                    disabled=''
                    onClick={() => handleReset(clearFilters)}
                    style={{ marginRight: '10px' }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    );
}

// By the Numbers
function ByTheNumbersComponent(props) {
    const {
        exportActivities
    } = props;

    // By the Numbers vars
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Define by the numbers counts
    const roleCount = {};
    const statusCount = {};

    const incrementItem = (obj, item) => {
        if (!obj[item]) {
            obj[item] = 0;
        }
        obj[item] += 1;
    }
    for (const item of exportActivities) {
        incrementItem(roleCount, item.role);
        incrementItem(statusCount, item.status);
    }

    return (
        <>
            <Button
                type='primary'
                icon={
                    <FileSearchOutlined />
                }
                onClick={() => {
                    setIsModalOpen((isModalOpen) => !isModalOpen)
                }}
                className='coaching-dashboard-button'
                style={{
                    position: 'absolute',
                    left: '25px',
                }}
            >
                By the Numbers
            </Button>
            <Modal
                title='By The Numbers'
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    setIsModalOpen((isModalOpen) => !isModalOpen)
                }}
                bodyStyle={{
                    padding: '10px'
                }}
            >
                <Tabs
                    defaultActiveKey="1"
                    tabPosition='left'
                    style={{ height: 650 }}
                >
                    <Tabs.TabPane tab="Totals" key="1">
                        <Statistic title='Total Actions' value={exportActivities.length} />
                        <Divider orientation="left" style={{ color: '#1890ff', fontSize: '14px' }}>Status Breakdown</Divider>
                        {Object.entries(statusCount).map(([key, value]) =>
                            <Statistic title={key} value={value} />
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        </>
    )
}

// Coaching Activity Tracker Component
function CoachingActivityTracker() {

    const [maxCount, setMaxCount] = useState(false);
    const [exportActivities, setExportActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    // Date vars
    const [date, setDate] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [value, setValue] = useState('');
    // Search vars
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchText, setSearchText] = useState('');
    // Filter vars
    const [trackRole, setTrackRole] = useState('');
    const [roleList, setRoleList] = useState([]);
    const [trackUser, setTrackUser] = useState('');
    const [userList, setUserList] = useState([]);
    const [trackStatus, setTrackStatus] = useState('');
    const [statusList, setStatusList] = useState([]);

    const { getHeaders } = useContinuousAuth();

    // Fetch Activity Payload (On Load)
    useEffect(() => {
        setLoading(true);
        fetchActivities(startDate, endDate, trackRole, trackUser, trackStatus)
            .then(activities => {
                setExportActivities(activities.payload);
                // Results Check
                setMaxCount(!!activities.maxResults);
            })
            .catch(e => {
                message.error('There was a problem with your Request');
                console.error(e.message);
            })
            .finally(() => setLoading(false));
    }, [startDate, endDate, trackRole, trackUser, trackStatus]);

    // Fetch Users/Roles (On Load)
    useEffect(() => {
        fetchSearchOptions();
    }, []);

    // Fetch Activities
    const fetchActivities = async (startDate, endDate, trackRole, trackUser, trackStatus) => {
        try {
            const params = {};
            // filter results by set date range
            if (startDate && endDate) {
                params.startDate = startDate;
                params.endDate = endDate;
            }
            // filter results by role
            if (trackRole) {
                params.roleFilter = trackRole;
            }
            // filter results by user
            if (trackUser) {
                params.userFilter = trackUser;
            }
            // filter results by status
            if (trackStatus) {
                params.statusFilter = trackStatus;
            }
            const headers = await getHeaders();
            const { data } = await axios.get(apiPrefix + '/api/coaching-activity', { headers, params });
            return data;
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        }
    };

    // User/Role Fetch
    const fetchSearchOptions = async () => {
        try {
            const params = {};
            const headers = await getHeaders();
            const { data } = await axios.get(apiPrefix + '/api/coaching-activity', { headers, params });
            let users = [];
            let roles = [];
            for (const activity of data.payload) {
                if (!users.includes(activity.user)) users.push(activity.user);
                if (!roles.includes(activity.role)) roles.push(activity.role);
            }
            setUserList(users);
            setRoleList(roles);
            setStatusList(['Search', 'Create', 'Rework', 'CS Rework', 'Approved', 'Delivered', 'Deleted']);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        }
    };

    // Fetch Activities (User Change, Date Filter or Role Filter)
    const activityFetch = async (startDate, endDate, trackRole, trackUser, trackStatus) => {
        setLoading(true);
        try {
            const results = await fetchActivities(startDate, endDate, trackRole, trackUser, trackStatus);
            setExportActivities(results.payload);
            // Results Check
            setMaxCount(!!results.maxResults);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error(e.message);
        } finally {
            setLoading(false)
        }
    };

    // Define Column Search Properties
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: (props) => (
            <ColumnSearch
                {...{
                    ...props,
                    dataIndex,
                    setSearchText,
                    setSearchedColumn,
                }}
            />
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    // Define Table Columns
    const columns = [
        {
            title: 'Date (UTC)',
            className: 'coaching-dashboard-column',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                multiple: 1,
            },
        },
        {
            title: 'Timestamp',
            className: 'coaching-dashboard-column',
            dataIndex: 'timestamp',
            sorter: {
                compare: (a, b) => new Date(a.timestamp * 1000).getTime() - new Date(b.timestamp * 1000).getTime(),
                multiple: 1,
            },
            render(timestamp, _row) {
                return new Date(timestamp * 1000).toString().substring(16);
            }
        },
        {
            title: 'User',
            className: 'coaching-dashboard-column-large',
            dataIndex: 'user',
            ...getColumnSearchProps('user'),
        },
        {
            title: 'Role',
            className: 'coaching-dashboard-column-large',
            dataIndex: 'role',
            sorter: {
                compare: (a, b) => a.role.localeCompare(b.role),
                multiple: 2,
            },
        },
        {
            title: 'Status',
            className: 'coaching-dashboard-column-large',
            dataIndex: 'status',
        },
    ];

    // Date Picker (date change, set disabled dates, set date)
    const onDateChange = (value, dateString) => {
        setValue(value);
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };
    const disabledDate = current => {
        if (!date || date.length === 0) {
            return false;
        }
        const tooLate = date[0] && current.diff(date[0], 'days') > 180;
        const tooEarly = date[1] && date[1].diff(current, 'days') > 0;
        return tooEarly || tooLate;
    };
    const onOpenChange = open => {
        if (open) {
            setDate([]);
        }
    };

    // Dropdown Options
    const roleListOptions = roleList?.map((trackRole) => ({ value: trackRole, label: trackRole }));
    const userListOptions = userList?.map((trackUser) => ({ value: trackUser, label: trackUser }));
    const statusListOptions = statusList?.map((trackStatus) => ({ value: trackStatus, label: trackStatus }));

    // Dropdown Styling
    const dropdownStyle = {
        control: (provided) => ({
            ...provided,
            height: '42.5px',
            minHeight: '42.5px',
            cursor: 'pointer',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 20, // Needed for Table Header
        }),
    };

    // Coaching Activities
    return (
        <div>
            <div className='coaching-dashboard-wrapper'>
                <Card title='Filters' bodyStyle={{ padding: '24px 24px 12px' }} style={{ borderTop: 'inherit' }}>
                    <Row type='flex' gutter={8}>
                        <Col>
                            <RangePicker
                                className='coaching-dashboard-date'
                                onChange={onDateChange}
                                value={value || []}
                                disabledDate={disabledDate}
                                onCalendarChange={(value) => { setDate(value) }}
                                onOpenChange={onOpenChange}
                            />
                            <p className='coaching-description muted-text'>
                                Default Range | Last 30 Days <br />
                                {maxCount &&
                                    <span style={{ color: '#FF0000' }}>
                                        Max Results Displayed, please consider reducing your Date Range
                                    </span>
                                }
                            </p>
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 8 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={roleListOptions}
                                onChange={(selectedOption) => {
                                    setTrackRole(selectedOption.value);
                                }}
                                value={roleListOptions.find((option) => option.value === trackRole) || ''}
                                placeholder='Filter by Role'
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 8 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={userListOptions}
                                onChange={(selectedOption) => {
                                    setTrackUser(selectedOption.value);
                                }}
                                value={userListOptions.find((option) => option.value === trackUser) || ''}
                                placeholder='Filter by User'
                                styles={dropdownStyle}
                            />
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            sm={{ span: 8 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                            xxl={{ span: 4 }}
                        >
                            <Select
                                options={statusListOptions}
                                onChange={(selectedOption) => {
                                    setTrackStatus(selectedOption.value);
                                }}
                                value={statusListOptions.find((option) => option.value === trackStatus) || ''}
                                placeholder='Filter by Status'
                                styles={dropdownStyle}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card bodyStyle={{ padding: '12px 24px 24px' }} style={{ borderTop: 'inherit' }}>
                    <ByTheNumbersComponent
                        exportActivities={exportActivities}
                    />
                    <Row justify='end' type='flex'>
                        <Col>
                            <Button
                                danger
                                className='coaching-dashboard-button'
                                disabled=''
                                onClick={() => {
                                    activityFetch('', '', '', '');
                                    setTrackRole('');
                                    setTrackUser('');
                                    setTrackStatus('');
                                    setValue('');
                                }}
                                style={{ marginRight: '10px' }}
                            >
                                Reset
                            </Button>
                            <Button
                                type='primary'
                                className='coaching-dashboard-button'
                                disabled=''
                                onClick={() => {
                                    activityFetch(startDate, endDate, trackRole, trackUser, trackStatus);
                                }}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Card>
                <div className='coaching-dashboard-table' style={{ marginTop: '20px' }}>
                    <Table
                        columns={columns}
                        scroll={{ x: 1300 }}
                        rowKey={record => record.formId}
                        loading={loading}
                        dataSource={exportActivities || null}
                    />
                </div>
            </div>
        </div>
    );
}

export default CoachingActivityTracker;
