import React, { FC, useMemo } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Underline } from '@tiptap/extension-underline';
import DOMPurify from 'dompurify';

interface HtmlRendererProps {
    html: string;
}

const HtmlRenderer: FC<HtmlRendererProps> = ({ html }) => {
    const sanitizedHtml = useMemo(() => DOMPurify.sanitize(html, {
        USE_PROFILES: { html: true }
    }), [html]);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link,
            Underline
        ],
        content: sanitizedHtml,
        editable: false,
    });

    return (
        <EditorContent editor={editor} />
    );
};

export default HtmlRenderer;
