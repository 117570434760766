export default [
    {
        Category: "Attendance",
        "reasons": [{
            value: "Failure to Notify",
            action: true
        },
            {
                value: "No Call No Show",
                action: true
            },
            {
                value: "Occurrence",
                action: true
            }]
    },
    {
        Category: "Behavioral",
        "reasons": [{
            value: "Policy Violation",
            action: true
        }]
    },
    {
        Category: "Coaching",
        "reasons": [{
            value: "1 on 1",
            action: false
        },
            {
                value: "Quick Hit",
                action: false
            },
            {
                value: "Recognition",
                action: false
            },
            {
                value: "Skill Builder",
                action: false
            },
            {
                value: "Team Communication",
                action: false
            }]
    },
    {
        Category: "Performance",
        "reasons": [{
            value: "Performance Management Plan",
            action: true
        },
            {
                value: "QA Review",
                action: true
            },
            {
                value: "Scorecard Review",
                action: true
            },
            {
                value: "Supervisor Hand-off",
                action: false
            }]
    },
]
