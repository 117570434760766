import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useContinuousAuth, ContinuousAuthProvider } from '../../pages/App';
import OktaLogin from '../okta-auth-container/OktaLogin';
import Seo from '../seo/Seo';
import settings from '../../settings.json';

const { okta: { cid } } = (settings as any)[window.location.host] || settings.default;

const Page = styled.main<{
    color: string;
}>`
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.color};
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

const Content = styled.div<{
    color: string;
    expanded?: boolean;
}>`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.color};
    width: 100%;
    position: relative;

    ${props => !props.expanded && css`
        padding: 20px;
        max-width: 1400px;
        align-self: center;
    `}
`;

const Overlay = styled.div`
    position: absolute;
    z-index: 5;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: #ffffffd0;
`;

const StyledFooter = styled.footer`
	text-align: center;
	padding: 1.5em;
	background: #f9f9f9;
	font-size: 14px;
`;

type Props = PropsWithChildren<{
    bodyColor?: string;
    headerColor?: string;
    header?: string;
    subheader?: string;
    expanded?: boolean;
    footer?: boolean;
    authenticated?: boolean;
}>;


type ContinuousAuthContentProps = PropsWithChildren<{
    authOverlay: ReactNode;
}>

function ContinuousAuthContent(props: ContinuousAuthContentProps) {
    const {
        children,
        authOverlay,
    } = props;

    const {
        authState,
        isAuthenticated,
    } = useContinuousAuth();

    return (
        <>
            { !isAuthenticated && authOverlay }
            {authState && children}
        </>
    );
}

function PageContent(props: Props) {
    const {
        bodyColor = '#f9f9f9',
        header,
        subheader,
        expanded,
        children,
        footer,
        authenticated,
    } = props;

    return (
        <Page color={bodyColor}>
            <Seo title={(subheader ? `${subheader} | ` : '') + (header || 'CS Tools')} />
            <Content color={bodyColor} expanded={expanded}>
                {authenticated ? (
                    <ContinuousAuthProvider>
                        <ContinuousAuthContent authOverlay={
                            <Overlay>
                                <OktaLogin />
                            </Overlay>
                        }>
                            {children}
                        </ContinuousAuthContent>
                    </ContinuousAuthProvider>
                ) : (
                    children
                )}
            </Content>

            {footer && (
                <StyledFooter>
                    © {new Date().getFullYear()}, Built by the Ring CS Engineering Team
                </StyledFooter>
            )}
        </Page>
    );
}

export default PageContent;
