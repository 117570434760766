import React, { useEffect, useState } from 'react';
import PageHeader from '../components/layouts/PageHeader';
import PageContent from '../components/layouts/PageContent';
import axios from 'axios';
import styled from 'styled-components';

//Elements
import {
    Affix,
    Alert,
    Button,
    Drawer,
    List,
    message,
    Popover,
    Spin
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

//CSS
import '../components/coaching-corner/coaching-corner.css';
import '../components/layouts/base.css';

//Components
import CoachingForm from '../components/coaching-corner/CoachingForm';
import CoachingDashboard from '../components/coaching-corner/CoachingDashboard';
import CoachingActivityTracker from '../components/coaching-corner/CoachingActivityTracker';
import CsqaForm from '../components/csqa-platform/CsqaForm';
import CsqaDashboard from '../components/csqa-platform/CsqaDashboard';
import whatsNewLibrary from '../components/coaching-corner/whats-new-library';

//Variables
import settings from '../settings.json';

//URL Prefix Defined & OktaAuth
const settingEnv = (settings as any)[window.location.host] || settings.default;
const {
    api_prefix: apiPrefix,
    cstools: { enableBanner },
} = settingEnv;

import { useContinuousAuth } from './App';

export function EngagementSuite() {
    return (
        <PageContent
            expanded={true}
            footer={true}
            authenticated={true}
        >
            <AuthedEngagementSuite />
        </PageContent>
    );
}


//styled components
const BodyWrapper = styled.div`
    height: 100vh;
`;
const LoadingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 100;
`;
const LoadingText = styled.div`
    color: #1677ff;
    margin-top: 10px;
`;

const RoleIndicator = styled.div`
    background-color: #fff;
    border: 1px solid #a4b3c3;
    border-radius: 5px;
    bottom: 10px;
    color: #a4b3c3;
    cursor: pointer;
    height: 55px;
    padding: 15px;
    position: fixed;
    width: fit-content;
    left: 25px;
    font-size: 18px;
    font-weight: 600;
    z-index: 1;
`;
const RoleValue = styled.span`
    font-family: 'equiplight';
    margin-left: 5px;
`;
const RoleIndicatorContent = styled.div`
    width: 300px;
`;
const WhatsNewComponent = styled.div`
    display: flex;
    justify-content: flex-end;
    z-index: 10;
    height: 0px;
`;
const WhatsNewHeader = styled.div`
    color: #10628A;
    font-size: 20px;
`;

const roleIndicatorContent = (
    <RoleIndicatorContent>
        <p>Access to EngagementSuite is managed through Okta.
            If your current role listed is incorrect, please contact
            <a href="mailto:WFM@ring.com">WFM@ring.com</a> for assistance.
        </p>
    </RoleIndicatorContent>
);



function AuthedEngagementSuite() {
    const [authorizedUser, setAuthorizedUser] = useState(false);
    const [createKey, setCreateKey] = useState('');
    const [current, setCurrent] = useState('');
    const [dashboardKey, setDashboardKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState('');
    const [org, setOrg] = useState('');
    const [userPermissions, setUserPermissions] = useState<Set<string>>(new Set());
    const [role, setRole] = useState('');
    const [tempRole, setTempRole] = useState<string>('');
    const [showDrawer, setShowDrawer] = useState(false);

    const { getHeaders } = useContinuousAuth();
    const navigate = useNavigate();

    type Permissions = string;

    // Access Check - User PermissionSet must include at least one of the following Permissions
    const allowedPermissions = new Set<string>([
        "createCsqa",
        "searchCsqa",
        "viewCsqa",
        "createCoaching",
        "searchCoaching",
        "viewCoaching"
    ]);

    const getUserPermissions = async () => {
        setLoading(true);
        // define user permissions set
        let permissionsSet = new Set<string>();
        const headers = await getHeaders();
        try {
            const { data } = await axios.get<Permissions[]>(apiPrefix + '/api/permissions', { headers });
            if (data) {
                permissionsSet = new Set(data);

                // Auth Check - see if user has any of the allowed permissions
                const hasAllowedPermission = [...permissionsSet].some(permission => allowedPermissions.has(permission));
                setAuthorizedUser(hasAllowedPermission);

                // If user does not have required permissions, navigate to UnauthorizedUser page prior to assigning Role
                if (!hasAllowedPermission) {
                    navigate('/unauthorized');
                    return;
                } else {
                    menuClick('CSQA Dashboard', '', 'csqa');
                }
                // Admin Check - Override Role for Admin Users
                if (permissionsSet.has('adminViewCoaching')) {
                    setRole('Admin');
                }
            }

        } catch (error) {
            console.error('Error fetching permissions:', error);
        } finally {
            setUserPermissions(permissionsSet);
            setLoading(false);
        }
    };

    // Define User Okta Groups
    useEffect(() => {
        getUserPermissions();
    }, []);

    // Assign Role (for authorized users)
    useEffect(() => {
        if (authorizedUser) {
            assignRole();
        }
    }, [role, authorizedUser]);

    type EmployeeRole = {
        user: string,
        role: string,
        org: string
    };

    // Assign User Role
    const assignRole = async () => {
        try {
            setLoading(true);
            const headers = await getHeaders();

            const { data } = await axios.get<EmployeeRole>(apiPrefix + '/api/agent-role', { headers });
            setUser(data.user);
            if (data.role) {
                setRole(data.role);
            }
            if (data.org) {
                setOrg(data.org);
            }
        } catch (e: any) {
            message.error('There was a problem defining your Role');
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    // Define Click Events
    const menuClick = (menuTitle: string, createKey: string, dashboardKey: string) => {
        setCurrent(menuTitle);
        setCreateKey(createKey);
        setDashboardKey(dashboardKey);
    };
    const tempRoleClick = (value: any) => {
        setTempRole(value.value);
        switch (value) {
            case 'Agent':
                menuClick('Coaching Dashboard', 'coaching', '');
                userPermissions.delete('createCsqa');
                userPermissions.delete('createCoaching');
                break;
            case 'Lead':
            case 'Supervisor':
            case 'Hr':
                userPermissions.add('createCoaching');
                userPermissions.delete('createCsqa');
                break;
            case 'Admin':
                userPermissions.add('createCoaching');
                userPermissions.add('createCsqa');
                break;
            default:
                // Maybe log something if unrecognized role?
                break;
        }
    };
    const tempUserClick = (value: any) => {
        setUser(value);
    };

    // Home Page Content
    return (
        <BodyWrapper>
            {loading &&
                <LoadingOverlay>
                    <Spin size="large"/>
                    <LoadingText>
                        Loading User Permissions...
                    </LoadingText>
                </LoadingOverlay>
            }
            {/* WHAT'S NEW COMPONENT */}
            <WhatsNewComponent>
                <Affix
                    offsetTop={10}
                    style={{
                        display: 'flex'
                    }}
                >
                    <Button
                        icon={<InfoCircleOutlined rev={undefined} />}
                        type='default'
                        shape='circle'
                        onClick={() => setShowDrawer(true)}
                        style={{
                            position: 'relative',
                            right: '20px',
                            top: '10px'
                        }}
                    />
                </Affix>
                <Drawer
                    title={<WhatsNewHeader>What's New</WhatsNewHeader>}
                    placement="left"
                    onClose={() => setShowDrawer(false)}
                    open={showDrawer}
                    width='500px'
                    bodyStyle={{
                        padding: '5px 24px'
                    }}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={whatsNewLibrary}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={item.icon}
                                    title={item.title}
                                    description={item.date}
                                />
                                {item.content}
                            </List.Item>
                        )}
                    />
                </Drawer>
            </WhatsNewComponent>
            {/* HEADER COMPONENT */}
            <PageHeader
                userPermissions={userPermissions}
                menuClick={menuClick}
                tempRoleClick={tempRoleClick}
                tempUserClick={tempUserClick}
                current={current}
                createKey={createKey}
                dashboardKey={dashboardKey}
                user={user}
                org={org}
                tempRole={tempRole}
            />
            {enableBanner &&
                <Alert
                    message='Please Advise'
                    description='This is a testing enviornment, submissions entered will not be accessible in production.'
                    banner
                    closable
                />
            }
            {
                current === 'Create Coaching' &&
                <CoachingForm
                    menuClick={menuClick}
                    user={user}
                    role={tempRole || role}
                    userPermissions={userPermissions}
                />
            }
            {
                current === 'Coaching Dashboard' &&
                <CoachingDashboard
                    user={user}
                    role={role}
                    tempRole={tempRole}
                    userPermissions={userPermissions}
                />
            }
            {
                current === 'Activity Tracker' &&
                <CoachingActivityTracker />
            }
            {
                current === 'Create CSQA Review' &&
                <CsqaForm
                    menuClick={menuClick}
                    user={user}
                    role={tempRole || role}
                    userPermissions={userPermissions}
                    org={org}
                />
            }
            {
                current === 'CSQA Dashboard' &&
                <CsqaDashboard
                    userPermissions={userPermissions}
                />
            }
            {/* ROLE INDICATOR */}
            <Popover content={roleIndicatorContent} title='ACCESS ISSUES?'>
                <RoleIndicator>
                    Current Role: <RoleValue>{role}</RoleValue>
                </RoleIndicator>
            </Popover>
        </BodyWrapper >
    )
}

export default AuthedEngagementSuite;
