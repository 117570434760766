
import React from 'react';
import { BulbOutlined, ShareAltOutlined } from '@ant-design/icons';

export default [
    {
        title: 'CSQA -> Coaching Corner Integration',
        date: 'Version: 1.0.1 | 11/11/2022',
        icon: <ShareAltOutlined style={{ color: '#F1670D', fontSize: '30px'  }} />,
        content: `This feature automates the creation of a coaching record for any CSQA review that receives a red flag. 
        These coachings will be marked "Ready for Delivery" with the Reason of "QA Review". 
        Once you deliver the coaching, the CSQA team will automatically be informed alleviating the need for you to update manually.`
    },
    {
        title: 'More Releases Coming Soon!',
        date: 'Version: 1.0.2 | TBD',
        icon: <BulbOutlined style={{ color: '#F1670D', fontSize: '30px' }} />,
        content: `Stay tuned for more feature release notes!`
    },
];
